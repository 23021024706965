
<div class="card-head">
<!--  *ngIf="groupeType=='SUPERgroupe'"-->
  <button  class="btn btn-success btn-sm pull-right" (click)="openModal(actiongroupe)">
    <i class="fa fa-plus" aria-hidden="true"></i> {{LANGAGE.nouveau}}
  </button>
</div>

<div class="card-body">
  <div class="row">
    <!-- <table class="table"> -->
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th>{{LANGAGE.groupeid1}}</th>
        <th *ngIf="listentreprise.length>0" >{{LANGAGE.nomEntreprise}}</th>
        <th>{{LANGAGE.invoiceaccountAX}}</th>
        <th>{{LANGAGE.InvoiceName}}</th>
        <th>{{LANGAGE.CustomerReference}}</th>
        <th>{{LANGAGE.adresse}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listgroupe" class="pointer-cl">
        <td  (click)="emitDatagroupe(item)" >{{item.groupeId}}</td>
        <td  (click)="emitDatagroupe(item)" *ngIf="listentreprise.length>0" >{{( item.entreprise && item.entreprise.nom) ? item.entreprise.nom : ''}}</td>
        <td  (click)="emitDatagroupe(item)">{{item.invoiceaccountAX ? item.invoiceaccountAX: ''}}</td>
        <td  (click)="emitDatagroupe(item)">{{item.InvoiceName ? item.InvoiceName: ''}}</td>
        <td  (click)="emitDatagroupe(item)">{{item.CustomerReference ? item.CustomerReference: ''}}</td>
        <td  (click)="emitDatagroupe(item)">{{item.InvoiceAddress ? item.InvoiceAddress : ''}}</td>
        <td>
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #actiongroupe>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold" >{{LANGAGE.nouveaugroupe}}</h4>
    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="adminType=='SUPERADMIN'" class="form-group">
      <label>{{LANGAGE.entreprise}}</label>
      <select  [(ngModel)]="entrepriseId" [ngModelOptions]="{standalone: true}" class="form-control">
        <option *ngFor="let item of listentreprise; let y = index;"  value="{{item.id}}">{{item.nom}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{LANGAGE.groupeid1}}</label>
      <input type="text" class="form-control" name="groupeName" [(ngModel)]="groupeId">
    </div>

    <div class="form-group">
      <label>{{LANGAGE.InvoiceName}}</label>
      <input type="text" class="form-control" name="groupeName" [(ngModel)]="InvoiceName">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.invoiceaccountAX}}</label>
      <input type="text" class="form-control" name="groupeAdress" [(ngModel)]="invoiceaccountAX">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.CustomerReference}}</label>
      <input type="text" class="form-control" name="groupeAdress" [(ngModel)]="CustomerReference">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.InvoiceAddress}}</label>
      <input type="text" class="form-control" name="groupeAdress" [(ngModel)]="InvoiceAddress">
    </div>


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info"  style="background-color: #1b3762; color:#fff;" (click)="addgroupe()"><i class="fa fa-plus-circle" aria-hidden="true"></i> {{LANGAGE.ajouter}}</button>
  </div>
</ng-template>


<ng-template #actiongroupe1>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold">{{LANGAGE.modificationgroupe}}</h4>

    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>

  <div class="modal-body">
    <div *ngIf="adminType=='SUPERADMIN'" class="form-group">
      <label>{{LANGAGE.entreprise}}</label>
      <select [(ngModel)]="entrepriseId" [ngModelOptions]="{standalone: true}" class="form-control">
        <option  *ngFor="let item of listentreprise; let y = index;"  [selected]="item.id == entrepriseId? true :false" value="{{item.id}}">{{item.nom}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{LANGAGE.groupeid1}}</label>
      <input type="text" class="form-control" name="groupeName" [(ngModel)]="groupeId">
    </div>

    <div class="form-group">
      <label>{{LANGAGE.InvoiceName}}</label>
      <input type="text" class="form-control" name="groupeName" [(ngModel)]="InvoiceName">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.InvoiceaccountAX}}</label>
      <input type="text" class="form-control" name="groupeAdress" [(ngModel)]="invoiceaccountAX">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.CustomerReference}}</label>
      <input type="text" class="form-control" name="groupeAdress" [(ngModel)]="CustomerReference">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.InvoiceAddress}}</label>
      <input type="text" class="form-control" name="groupeAdress" [(ngModel)]="InvoiceAddress">
    </div>
  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-info"  style="background-color: #1b3762; color:#fff;" (click)="updategroupe()"><i class="fa fa-check-circle" aria-hidden="true"></i> {{LANGAGE.enregistrer}}</button>
  </div>
</ng-template>

<ng-template #confirmDelete>
  <div class="card-body">
    {{LANGAGE.etesvoussurdevouloirsupprimer}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deletegroupe()">{{LANGAGE.oui}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">{{LANGAGE.non}}</button>
  </div>
</ng-template>

