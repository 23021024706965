
<!--<div class="card-head">-->
<!--  <button  class="btn btn-success btn-sm pull-right" (click)="openModal(actionmessage)">-->
<!--    <i class="fa fa-plus" aria-hidden="true"></i> Nouveau-->
<!--  </button>-->
<!--</div>-->

<div class="card-body">
  <div class="row">
    <!-- <table class="table"> -->
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th>{{LANGAGE.entreprise}}</th>
        <th>{{LANGAGE.email}}</th>
        <th>{{LANGAGE.message1}}</th>
        <th>{{LANGAGE.date1}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listmessage" class="pointer-cl">
        <td (click)="emitDatamessage(item)">{{item.entreprise.nom}}</td>
        <td (click)="emitDatamessage(item)">{{item.email}}</td>
        <td (click)="emitDatamessage(item)">{{item.message.length>100 ? item.message.substr(0,100) + '...' : item.message}}</td>
        <td (click)="emitDatamessage(item)">{{item.createdAt | date:'dd/MM/yyyy HH:mm:ss'}}</td>
        <td>
        <div style="display:flex; flex-direction: row;">
          <button style="margin-right: 10px" class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
          <button class="btn btn-sm btn-success" (click)="repondre(item)">
            <div style="display:flex; flex-direction: row; align-items: center;">
              <i class="fas fa-reply"></i>
              <i style="margin-left: 8px" *ngIf="item.isRepondu == true"  class="fas fa-check-double"></i>
            </div>
          </button>
        </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<!--<ng-template #actionmessage>-->
<!--  <div class="modal-header">-->
<!--    <h4  class="modal-title" style="font-size:20px; font-weight: bold" >Nouvelle entreprise</h4>-->
<!--    <div type="button" class="close" aria-label="Close" (click)="resetField()">-->
<!--      <span aria-hidden="true">&times;</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="modal-body">-->
<!--    <div class="form-group">-->
<!--      <label>Nom</label>-->
<!--      <input type="text" class="form-control" name="entrepriseName" [(ngModel)]="entrepriseName">-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      <label>Adresse</label>-->
<!--      <input type="text" class="form-control" name="entrepriseAdress" [(ngModel)]="entrepriseAdress">-->
<!--    </div>-->

<!--  </div>-->
<!--  <div class="modal-footer">-->
<!--    <button type="button" class="btn btn-info"  style="background-color: #1b3762; color:#fff;" (click)="addentreprise()"><i class="fa fa-plus-circle" aria-hidden="true"></i> Ajouter</button>-->
<!--  </div>-->
<!--</ng-template>-->


<ng-template #actionmessage1>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold">Message</h4>
    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Email: {{Item.email}}</label>
      <div style="font-size: 16px;">
        {{Item.message}}
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-info" style="background-color: #1b3762; color:#fff;"  (click)="repondre(Item)"><i class="fa fa-check-circle" aria-hidden="true"></i> {{LANGAGE.repondre}}</button>
  </div>
</ng-template>


<ng-template #actionmessage2>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold">{{LANGAGE.repondre}}</h4>
    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{LANGAGE.email}}: {{Item.email}}</label>
      <div class="form-group">
        <label>{{LANGAGE.objetemail}}</label>
        <input type="text" class="form-control" name="adminName" [(ngModel)]="Subject">
      </div>
      <label>{{LANGAGE.message1}}</label>
      <textarea type="text" class="form-control" name="hash" [(ngModel)]="newmessage" style="width: 100%; height: 200px;"></textarea>

    </div>

  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-info" style="background-color: #1b3762; color:#fff;"  (click)="reponse()"><i class="fa fa-check-circle" aria-hidden="true"></i> {{LANGAGE.envoyer}}</button>
  </div>
</ng-template>

<ng-template #confirmDelete>
  <div class="card-body">
    {{LANGAGE.etesvoussurdevouloirsupprimer}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deletemessage()">{{LANGAGE.oui}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">{{LANGAGE.non}}</button>
  </div>
</ng-template>

