<div class="card-body">

  <div style="display: flex; flex-direction: row; align-items: center; ">
    <app-date-filter (itemConcat)="filterByDate($event)"></app-date-filter>
    <select
      (change) = "updateAffichage($any($event.target).value)"
      style="margin-left:20px; width: 80px; background-color:  #274f8d; color: #fff; height: 33px; border-radius: 4px; margin-top: 10px; " matNativeControl required>
      <option value="Timeline" style="color: #fff;">{{LANGAGE.timeline}}</option>
      <option value="Tableau"  style="color: #fff;">{{LANGAGE.tableau}}</option>
    </select>
  </div>

  <div [hidden]="isTimeline"  class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th>{{LANGAGE.commande1}}</th>
        <th>{{LANGAGE.date}}</th>
        <th>{{LANGAGE.livraison}}</th>
        <th>{{LANGAGE.facturation}}</th>
        <th>{{LANGAGE.montant}}</th>
        <th>{{LANGAGE.noteenvoi}}</th>
        <th>{{LANGAGE.preuvelivraison}}</th>
        <th>{{LANGAGE.etatcommande}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listercommandes" class="pointer-cl">
        <td >{{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}</td>
        <td >{{(item.ConfirmDate !== null && item.ConfirmDate !== '' && item.ConfirmDate !== undefined) ? (item.ConfirmDate | titlecase) : ''}}</td>
        <td >{{(item.DeliveryAccountAX !== null && item.DeliveryAccountAX !== '' && item.DeliveryAccountAX !== undefined) ? (item.DeliveryAccountAX | titlecase) : ''}}</td>
        <td >{{(item.InvoiceAccountAX !== null && item.InvoiceAccountAX !== '' && item.InvoiceAccountAX !== undefined) ? (item.InvoiceAccountAX | titlecase) : ''}}</td>
        <td >{{(item.SalesBalance !== null && item.SalesBalance !== '' && item.SalesBalance !== undefined) ? (item.SalesBalance ) : ''}}</td>
        <td >{{(item.PackingSlipId !== null && item.PackingSlipId !== '' && item.PackingSlipId !== undefined) ? (item.PackingSlipId | titlecase) : ''}}</td>
        <td >
          <button class="btn btn-sm btn-info"  style="background-color: #274f8d; color:#fff; border-width: 0px;" (click)="preuvelivraison(item)">
            <i class="fas fa-download"></i>
          </button>
        </td>
        <td >
          {{formatEtatCommande(item)}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div  [hidden]="!isTimeline" class="row" style="margin-top: 15px;">
    <div *ngIf="listercommandes.length==0"  style="display: flex; align-items: center; justify-content: center; width: 100%;  font-size: 15px; color: #8f8f8f; padding: 25px; margin-top: 20px; margin-bottom: 20px;  ">
      <div>
        {{LANGAGE.aucunedonne}}
      </div>

    </div>
    <div *ngFor="let item of listercommandes; let i = index;" class="pointer-cl" style="display: flex;flex-direction: row; justify-content: space-between; align-items: center; margin-top: 30px;">
      <div style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; justify-content: center;">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.commande2}}
        </div>
        <div *ngIf="item.dateisorderresponse" style=" text-align: center; font-size: 10px;">
          {{item.dateisorderresponse ? formatdate(item.dateisorderresponse) : ''}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="bggreen" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;"><i class="fas fa-check"></i></div>
        <div style=" text-align: center;">
          {{formatcommandevalide(item)}}
        </div>
        <div *ngIf="item.SalesBalance" style=" text-align: center; font-size: 10px; font-weight: 800; color:#1fb820">
          {{LANGAGE.montant1}}: {{item.SalesBalance ? item.SalesBalance : ''}}
        </div>

      </div>


      <div [style.background-color]="bggreen"  style="width: 20%; height: 3px;">
      </div>


      <div style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; ">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.etat}}
        </div>
        <div *ngIf="item.dateisShipment" style=" text-align: center; font-size: 10px; color:#44b1ac">
          {{item.dateisShipment ? formatdate(item.dateisShipment) : ''}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="formatetat(item) != LANGAGE.commandpartiellementprete  ? bggreen : bgorange" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;"><i class="fas fa-check"   style="color: #fff;"></i></div>
        <div style=" text-align: center;">
          {{formatetat(item)}}
        </div>
      </div>




      <!--      progress1-->
      <div *ngIf="formatetat(item) == LANGAGE.commandpartiellementprete"  style="display: flex; flex-direction: row; align-items: center; width: 20%; height: 3px;">
        <div [style.background-color]="bggreen"  style="width: 50%; height: 3px;">
        </div>
        <div [style.background-color]="bggray"  style="width: 50%; height: 3px;">
        </div>
      </div>
      <div *ngIf="formatetat(item) != LANGAGE.commandpartiellementprete " [style.background-color]="bggreen"  style="width: 20%; height: 3px;">
      </div>



      <div style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; ">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.livraison}}
        </div>
        <div *ngIf="item.dateisShipment" style=" text-align: center; font-size: 10px; color:#44b1ac">
          {{item.dateisShipment ? formatdate(item.dateisShipment) : ''}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="item.isEnroute  ? bggreen:bgorange" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;">
          <div *ngIf="item.isEnroute">
            <i  class="fas fa-check"  style="color: #ffffff;"></i>
          </div>
          <div *ngIf="!item.isEnroute" style="font-size: 18px; color:#fff; font-weight: bold;">
            X
          </div>
        </div>
        <div style=" text-align: center;">
          {{item.isEnroute? LANGAGE.enroute : LANGAGE.enattentedateexpedition}}
        </div>
      </div>




      <!--      progress2-->
      <div *ngIf="!item.isEnroute &&( formatetat(item) == LANGAGE.commandpartiellementprete)" [style.background-color]="bggray"  style="width: 20%; height: 3px;">
      </div>
      <div *ngIf="!item.isEnroute &&( formatetat(item) != LANGAGE.commandpartiellementprete)"  style="display: flex; flex-direction: row; align-items: center; width: 20%; height: 3px;">
        <div [style.background-color]="bggreen"  style="width: 50%; height: 3px;">
        </div>
        <div [style.background-color]="bggray"  style="width: 50%; height: 3px;">
        </div>
      </div>
      <div *ngIf="item.isEnroute" [style.background-color]="bggreen"  style="width: 20%; height: 3px;">
      </div>



      <div style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; ">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.suivi}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="(item.isEnroute || item.isInsertedFacture) ? bggreen : bgorange" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;"><i class="fas fa-truck"  style="color: #fff;"></i></div>
        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 5px;">
<!--          suivi quand la livraison n'est pas effectué,-->
<!--          console de géolocalisation, desactivév apres-->
          <button (click)="suivrelivraison(item)" [style.background-color]="!item.isInsertedFacture ? bgred : bggray" *ngIf="!item.isInsertedFacture"  style="border-width: 0px; color: white; border-radius: 4px; padding: 2px;  display:flex; align-items: center; justify-content: center; font-size: 10px;">
          {{LANGAGE.suivre}}
          </button>
<!--          Télécharger, preuve de livraison activé,-->
          <div (click)="preuvelivraisontimeline(item)" [style.background-color]="item.isInsertedFacture ? bgpurple : bggray"  style="margin-left:5px; border-radius: 4px; padding-top: 2px;padding-bottom: 2px; padding-left: 2px; padding-right: 2px;  color:white; display:flex; align-items: center; justify-content: center; font-size: 10px;">
            {{LANGAGE.telecharger}}
          </div>
        </div>
      </div>




      <!--      progress3-->
      <div *ngIf="!item.isInsertedFacture" [style.background-color]="bggray"  style="width: 20%; height: 3px;">
      </div>
      <div *ngIf="item.isInsertedFacture" [style.background-color]="bggreen"  style="width: 20%; height: 3px;">
      </div>






      <div style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; ">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.facture1}}
        </div>
        <div *ngIf="item.dateisfacturation" style=" text-align: center; font-size: 10px; color:#44b1ac">
          {{item.dateisfacturation ? formatdate(item.dateisfacturation) : ''}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="item.isInsertedFacture ? bggreen : bgorange" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;"><i class="fas fa-file-invoice"   style="color: #fff;"></i></div>
        <div style="text-align: center;">
          {{item.isInsertedFacture ? LANGAGE.commandefacture : LANGAGE.factureenvoye}}
        </div>
      </div>




    </div>




  </div>
</div>





