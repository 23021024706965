<div class="card-body">

  <div style="display: flex; flex-direction: row; align-items: center; ">
    <app-date-filter (itemConcat)="filterByDate($event)"></app-date-filter>
    <select
      (change) = "updateAffichage($any($event.target).value)"
      style="margin-left:20px; width: 80px; background-color:  #274f8d; color: #fff; height: 33px; border-radius: 4px; margin-top: 10px; " matNativeControl required>
      <option value="Timeline" style="color: #fff;">{{LANGAGE.timeline}}</option>
      <option value="Tableau"  style="color: #fff;">{{LANGAGE.tableau}}</option>
    </select>
  </div>

  <div [hidden]="isTimeline"  class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th>{{LANGAGE.commande1}}</th>
        <th>{{LANGAGE.date}}</th>
        <th>{{LANGAGE.livraison}}</th>
        <th>{{LANGAGE.facturation}}</th>
        <th>{{LANGAGE.montant}}</th>
        <th>{{LANGAGE.noteenvoi}}</th>
        <th>{{LANGAGE.preuvelivraison}}</th>
        <th>{{LANGAGE.etatcommande}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listercommandes" class="pointer-cl">
        <td (click)="emitDetail(item)"  >{{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)" >{{(item.ConfirmDate !== null && item.ConfirmDate !== '' && item.ConfirmDate !== undefined) ? (item.ConfirmDate | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)" >{{(item.DeliveryAccountAX !== null && item.DeliveryAccountAX !== '' && item.DeliveryAccountAX !== undefined) ? (item.DeliveryAccountAX | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)" >{{(item.InvoiceAccountAX !== null && item.InvoiceAccountAX !== '' && item.InvoiceAccountAX !== undefined) ? (item.InvoiceAccountAX | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)" >{{(item.SalesBalance !== null && item.SalesBalance !== '' && item.SalesBalance !== undefined) ? (item.SalesBalance ) : ''}}</td>
        <td (click)="emitDetail(item)" >{{(item.PackingSlipId !== null && item.PackingSlipId !== '' && item.PackingSlipId !== undefined) ? (item.PackingSlipId | titlecase) : ''}}</td>
        <td >
          <button class="btn btn-sm btn-info" style="background-color: #274f8d; color:#fff; border-width: 0px;" (click)="preuvelivraison(item)">
            <i class="fas fa-download"></i>
          </button>
        </td>
        <td >
          {{formatEtatCommande(item)}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div  [hidden]="!isTimeline" class="row" style="margin-top: 15px;">
    <div *ngIf="listercommandes.length==0"  style="display: flex; align-items: center; justify-content: center; width: 100%;  font-size: 15px; color: #8f8f8f; padding: 25px; margin-top: 20px; margin-bottom: 20px;  ">
      <div>
        {{LANGAGE.aucunedonne}}
      </div>

    </div>
    <div *ngFor="let item of listercommandes; let i = index;" class="pointer-cl" style="display: flex;flex-direction: row; justify-content: space-between; align-items: center; margin-top: 30px;">

      <div (click)="emitDetail(item)"  style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; justify-content: center;">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.commande2}}
        </div>
        <div *ngIf="item.dateisorderresponse" style=" text-align: center; font-size: 10px;">
          {{item.dateisorderresponse ? formatdate(item.dateisorderresponse) : ''}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="bggreen" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;"><i class="fas fa-check" style="color: #fff;"></i></div>
        <div style=" text-align: center;">
          {{formatcommandevalide(item)}}
        </div>
        <div *ngIf="item.SalesBalance" style=" text-align: center; font-size: 10px; font-weight: 800; color:#1fb820">
          {{LANGAGE.montant1}}: {{item.SalesBalance ? item.SalesBalance : ''}}
        </div>

      </div>


        <div [style.background-color]="bggreen"  style="width: 20%; height: 3px;">
        </div>


      <div (click)="emitDetail(item)"  style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; ">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.etat}}
        </div>
        <div *ngIf="item.dateisShipment" style=" text-align: center; font-size: 10px; color:#44b1ac">
          {{item.dateisShipment ? formatdate(item.dateisShipment) : ''}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="formatetat(item) != LANGAGE.commandpartiellementprete  ? bggreen : bgorange" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;"><i class="fas fa-check" style="color: #fff;"></i></div>
        <div style=" text-align: center;">
          {{formatetat(item)}}
        </div>
      </div>



<!--      progress1-->
      <div *ngIf="formatetat(item) == LANGAGE.commandpartiellementprete"  style="display: flex; flex-direction: row; align-items: center; width: 20%; height: 3px;">
        <div [style.background-color]="bggreen"  style="width: 50%; height: 3px;">
        </div>
        <div [style.background-color]="bggray"  style="width: 50%; height: 3px;">
        </div>
      </div>
      <div *ngIf="formatetat(item) != LANGAGE.commandpartiellementprete" [style.background-color]="bggreen"  style="width: 20%; height: 3px;">
      </div>



      <div (click)="emitDetail(item)"  style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; ">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.livraison}}
        </div>
        <div *ngIf="item.dateisShipment" style=" text-align: center; font-size: 10px; color:#44b1ac">
          {{item.dateisShipment ? formatdate(item.dateisShipment) : ''}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="item.isEnroute ? bggreen:bgorange " style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;">
          <div *ngIf="item.isEnroute">
            <i  class="fas fa-check" style="color: #ffffff;"></i>
          </div>
          <div *ngIf="!item.isEnroute" style="font-size: 18px; color:#fff; font-weight: bold;">
            X
          </div>
        </div>
        <div style=" text-align: center;">
          {{item.isEnroute? LANGAGE.enroute : LANGAGE.enattentedateexpedition}}
        </div>
      </div>



      <!--      progress2-->
      <div *ngIf="!item.isEnroute &&( formatetat(item) == LANGAGE.commandpartiellementprete)" [style.background-color]="bggray"  style="width: 20%; height: 3px;">
      </div>
      <div *ngIf="!item.isEnroute &&( formatetat(item) != LANGAGE.commandpartiellementprete)"  style="display: flex; flex-direction: row; align-items: center; width: 20%; height: 3px;">
        <div [style.background-color]="bggreen"  style="width: 50%; height: 3px;">
        </div>
        <div [style.background-color]="bggray"  style="width: 50%; height: 3px;">
        </div>
      </div>
      <div *ngIf="item.isEnroute" [style.background-color]="bggreen"  style="width: 20%; height: 3px;">
      </div>



      <div (click)="emitDetail(item)"  style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; ">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.suivi}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="(item.isEnroute || item.isInsertedFacture) ? bggreen : bgorange" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;"><i class="fas fa-truck" style="color: #fff;"></i></div>
        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 5px;">
<!--          suivi quand la livraison n'est pas effectué,-->
<!--          console de géolocalisation, desactivév apres-->
          <button (click)="suivrelivraison(item)" [style.background-color]="!item.isInsertedFacture ? bgred : bggray" *ngIf="!item.isInsertedFacture"  style="border-width: 0px; color: white; border-radius: 4px; padding: 2px;  display:flex; align-items: center; justify-content: center; font-size: 10px;">
            {{LANGAGE.suivre}}
          </button>
<!--          Télécharger, preuve de livraison activé,-->
          <div (click)="preuvelivraisontimeline(item)" [style.background-color]="item.isInsertedFacture ? bgpurple : bggray"  style="margin-left:5px; border-radius: 4px; padding-top: 2px;padding-bottom: 2px; padding-left: 2px; padding-right: 2px;  color:white; display:flex; align-items: center; justify-content: center; font-size: 10px;">
            {{LANGAGE.telecharger}}
          </div>
        </div>
      </div>




      <!--      progress3-->
      <div *ngIf="!item.isInsertedFacture" [style.background-color]="bggray"  style="width: 20%; height: 3px;">
      </div>
      <div *ngIf="item.isInsertedFacture" [style.background-color]="bggreen"  style="width: 20%; height: 3px;">
      </div>





      <div (click)="emitDetail(item)"  style="display:flex; flex-direction: column; align-items: center; margin-top: 10px; ">
        <div [hidden]="i != 0 && true"  style="font-weight: bold; font-size: 15px; color: #21385A; margin-bottom: 7px; ">
          {{LANGAGE.facture1}}
        </div>
        <div *ngIf="item.dateisfacturation" style=" text-align: center; font-size: 10px; color:#44b1ac">
          {{item.dateisfacturation ? formatdate(item.dateisfacturation) : ''}}
        </div>
        {{(item.SalesId !== null && item.SalesId !== '' && item.SalesId !== undefined) ? (item.SalesId | titlecase) : ''}}
        <div [style.background-color]="item.isInsertedFacture ? bggreen : bgorange" style="border-radius: 50px; width:50px; height: 50px; display: flex; align-items: center; justify-content: center;"><i class="fas fa-file-invoice" style="color: #fff;"></i></div>
        <div style="text-align: center;">
          {{item.isInsertedFacture ? LANGAGE.commandefacture : LANGAGE.factureenvoye}}
        </div>
      </div>




    </div>




  </div>
</div>



<ng-template #actiondetails>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold">{{LANGAGE.detail}}</h4>
    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label >{{LANGAGE.numerocommande}} {{Item.numerocommande}}</label>
      <div style="padding-top: 10px"><label >{{LANGAGE.numerofacture1}} {{Item.numerofacture}}</label></div>
      <div style="padding-top: 10px"><label >{{LANGAGE.client}} {{details.Message.Header.Address.Supplier.SupplierName._text }}</label></div>
      <div style="padding-top: 10px"><label >{{LANGAGE.adresse}} {{details.Message.Header.Address.Delivery.DeliveryAddress._text }}</label></div>
      <div style="padding-top: 10px"><label >{{LANGAGE.livreur}} {{details.Message.Header.Address.Delivery.DeliveryName._text }}</label></div>

      <div *ngIf="!typeoff(details.Message.Lines.Line)"   style="font-size: 16px;">
        <div style="padding-top: 10px; padding-bottom: 10px"><label >{{LANGAGE.article}}</label></div>
        {{details.Message.Lines.Line.ItemDescription ? details.Message.Lines.Line.ItemDescription.Primary ? details.Message.Lines.Line.ItemDescription.Primary._text :'' : ''}}
      </div>
      <div *ngIf="typeoff(details.Message.Lines.Line)" style="font-size: 16px;">
        <div style="padding-top: 10px; margin-bottom: 10px; font-style: italic"><label >{{LANGAGE.articles}}</label></div>
        <ul class="list-group" style="width:99%">
          <li *ngFor="let item of details.Message.Lines.Line" class="list-group-item justify-content-between">
            {{item.ItemDescription.Primary._text}}
          </li>
        </ul>
      </div>
    </div>

  </div>
</ng-template>

