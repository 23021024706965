import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { DatatableLanguage } from "../../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as CryptoJS from 'crypto-js';
import {NgxSpinnerService} from "ngx-spinner";
import {ENV_PROD} from "../../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import {AlertService} from "../../../services/alert.service";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Francais} from "../../../../environments/Francais";
import {Anglais} from "../../../../environments/Anglais";


@Component({
  selector: 'app-groupe',
  templateUrl: './groupe.component.html',
  styleUrls: ['./groupe.component.css']
})
export class GroupeComponent implements OnInit, OnChanges {
  @ViewChild('actiongroupe') actiongroupe: any;
  @ViewChild('actiongroupe1') actiongroupe1: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public listgroupe: any = [];
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public groupeId: any = '';
  public InvoiceName: any = "";
  public invoiceaccountAX: any = "";
  public CustomerReference: any = "";
  public InvoiceAddress: any ="";
  public adminType: any; //superadmin or admin
  public entrepriseId: any; //pour modif
  public identifiant: any = null;
  public itementreprise: any = null;
  public listentreprise: any = [];
  public LANGAGE: any = Francais;

  // check action

  constructor(
    private httpClient: HttpClient,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.adminType = localStorage.getItem('adminType');
    if(this.adminType == "SUPERADMIN")
    this.itementreprise = localStorage.getItem('itementreprise');

    this.spinner.show();setTimeout(()=>{this.spinner.hide()},12000);
    this.getallgroupe();
  }

  ngOnChanges(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.loadgroupe()
  }

  /**
   * loadgroupe
   */
  public loadgroupe(): void {
    this.spinner.show();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.getallgroupe()
    })
  }

   private async getallgroupe(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      ////'Content-Type': 'application/json',
      Accept: "application/json",
       Authorization: "Bearer " + token
    });

    if(this.adminType == "SUPERADMIN"){
      const itemtypesuperadmin = localStorage.getItem('itemtypesuperadmin');
      if(itemtypesuperadmin == 'SUPERADMIN'){
        this.httpClient.get<any>(ENV_PROD.urlApi + 'groupe/getall',
          { headers: headers }
        ).pipe(retry(1), catchError(this.alertService.handleError))
          .subscribe(
            (res) => {
              this.spinner.hide();
              if (res.status === 200) {
                this.listgroupe = res.data;
                this.httpClient.get<any>(ENV_PROD.urlApi + 'entreprise/getAll',
                  {headers: headers}
                ).pipe(retry(1), catchError(this.alertService.handleError))
                  .subscribe(
                    async (res) => {
                      this.spinner.hide();
                      if (res.status === 200) {
                        console.log('le status', res);
                        this.listentreprise = res.data;
                        this.dtTigger.next()
                      }
                    });
              }
            })
      }else {

        this.httpClient.get<any>(ENV_PROD.urlApi + 'groupe/getbysuperadmin/'+this.itementreprise,
          { headers: headers }
        ).pipe(retry(1), catchError(this.alertService.handleError))
          .subscribe(
            (res) => {
              this.spinner.hide();
              if (res.status === 200) {
                this.listgroupe = res.data;
                this.httpClient.get<any>(ENV_PROD.urlApi + 'entreprise/getAll',
                  {headers: headers}
                ).pipe(retry(1), catchError(this.alertService.handleError))
                  .subscribe(
                    async (res) => {
                      this.spinner.hide();
                      if (res.status === 200) {
                        console.log('le status', res);
                        this.listentreprise = res.data;
                        this.dtTigger.next()
                      }
                    });
              }
            });
      }
    }
    else
      this.httpClient.get<any>(ENV_PROD.urlApi + 'groupe/getbyentreprise',
        { headers: headers }
      ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status === 200) {
              this.listgroupe = res.data;
              this.dtTigger.next()
            }
          });
  }

  /**
   * emitDatagroupe
   */
  public async emitDatagroupe(item: any) {
    this.emitData.emit(item);
    this.identifiant = item.id;
    this.groupeId = item.groupeId;
    this.entrepriseId = item.entrepriseId;
    this.InvoiceName = item.InvoiceName;
    this.invoiceaccountAX = item.invoiceaccountAX;
    this.CustomerReference = item.CustomerReference;
    this.InvoiceAddress = item.InvoiceAddress;
    await <any>this.ngbModal.open(this.actiongroupe1)
  }

   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.identifiant = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

   /**
   * addgroupe
   */
  public async addgroupe() {
     this.groupeId = this.groupeId.trim();
     this.InvoiceName = this.InvoiceName.trim();
     this.invoiceaccountAX = this.invoiceaccountAX.trim();
     this.CustomerReference = this.CustomerReference.trim();
     this.InvoiceAddress = this.InvoiceAddress.trim();
     if(this.entrepriseId == null && this.adminType =='SUPERADMIN'){
       alert(this.LANGAGE.veuillezchoisirentreprise);
       return;
     }
     if(this.groupeId == ""){
       alert(this.LANGAGE.veuillezajoutergroupid);
       return;
     }
     if(this.invoiceaccountAX == "" && this.CustomerReference == ""){
       alert(this.LANGAGE.veuillezajouterinvoiceorcustomer);
       return;
     }



    let body = {
      groupeId: this.groupeId,
    };
     if(this.adminType !='SUPERADMIN'){
       this.entrepriseId = await localStorage.getItem('entrepriseId');
       body['entrepriseId'] = this.entrepriseId;
     }else{
       body['entrepriseId'] = this.entrepriseId;
     }
     if(this.InvoiceName != ''){
       body['InvoiceName'] = this.InvoiceName;
     }

     if(this.invoiceaccountAX != ''){
       body['invoiceaccountAX'] = this.invoiceaccountAX;
     }
     if(this.CustomerReference != ''){
       body['CustomerReference'] = this.CustomerReference;
     }
     if(this.InvoiceAddress != ''){
       body['InvoiceAddress'] = this.InvoiceAddress;
     }
    console.log('bo',body)
     const token = localStorage.getItem('userToken');
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       Accept: "application/json",
       Authorization: "Bearer " + token
     });
     this.spinner.show();
     setTimeout(()=>this.spinner.hide(),10000);
     this.httpClient.post<any>(ENV_PROD.urlApi + 'groupe/add',
       JSON.stringify(body),
       { headers: headers, }
   ).pipe(retry(1), catchError(this.alertService.handleError))
       .subscribe(
         (res) => {
           this.spinner.hide();
           if(res.status && res.message ){
             this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
             //alert(ENV_PROD.nom_application + " \n " + res.message);
           }
           this.loadgroupe();
           this.resetField();
         });
  }

   /**
   * updategroupe
   */
  public updategroupe(): void{
     this.groupeId = this.groupeId.trim();

     if(this.groupeId === ""){
       alert(this.LANGAGE.legroupeidnepourraitetrepasvide);
       return;
     }

     if(this.invoiceaccountAX == "" && this.CustomerReference == ""){
       alert(this.LANGAGE.veuillezajouterinvoiceorcustomer);
       return;
     }

     let body = {
       id: this.identifiant,
       groupeId: this.groupeId,
       entrepriseId: this.entrepriseId
     };
     if(this.InvoiceName != ''){
       body['InvoiceName'] = this.InvoiceName;
     }
     if(this.invoiceaccountAX != ''){
       body['invoiceaccountAX'] = this.invoiceaccountAX;
     }
     if(this.CustomerReference != ''){
       body['CustomerReference'] = this.CustomerReference;
     }
     if(this.InvoiceAddress != ''){
       body['InvoiceAddress'] = this.InvoiceAddress;
     }

      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'groupe/update',
        JSON.stringify(body),
      { headers: headers},
    ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
          this.loadgroupe();
          this.resetField();
            if(res.status != 200)
              this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
        })

  }

  /**
   * deletegroupe
   */
  public deletegroupe() {
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'groupe/delete/'+ this.identifiant,
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.loadgroupe();
          this.resetField();
          if(res.status == 200){
            this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
          }
        });

  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

   // GEstion field data
   public resetField() {
    this.ngbModal.dismissAll();
    this.groupeId = '';
     this.InvoiceName = '';
     this.invoiceaccountAX = '';
     this.CustomerReference = '';
     this.InvoiceAddress = '';
  }




}
