

<div class="card-body">
  <div class="row">
    <!-- <table class="table"> -->
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th>{{LANGAGE.accountHkey}}</th>
        <th>{{LANGAGE.hash}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listhashkey" class="pointer-cl">
        <td >{{item.accountID}}</td>
        <td >{{item.hash}}</td><td>
          <button class="btn btn-sm btn-warning" (click)="openmodalmodification(item)">
            <i class="fas fa-edit"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>



<ng-template #actionadmin>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold">{{LANGAGE.modificationhkey}}</h4>

    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{LANGAGE.accountHkey}}</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="accountID">
    </div>

    <div class="form-group">
      <label>{{LANGAGE.hkey}}</label>
      <input type="text" class="form-control" name="hash" [(ngModel)]="hash">
    </div>

  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-info"  style="background-color: #1b3762; color:#fff;" (click)="modificationhashkey()"><i class="fa fa-check-circle" aria-hidden="true"></i> {{LANGAGE.enregistrer}}</button>
  </div>
</ng-template>



