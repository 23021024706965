import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {AdminService} from "../../services/admin.service";
import {ENV_PROD} from "../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {AlertService} from "../../services/alert.service";
import {Francais} from "../../../environments/Francais";
import {Anglais} from "../../../environments/Anglais";

@Component({
  selector: 'app-listercommandes',
  templateUrl: './listercommandes.component.html',
  styleUrls: ['./listercommandes.component.css']
})
export class listercommandesComponent implements OnInit {


  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild('actiondetails') actiondetails: any;

  @Output() navigateToAfficherJoueur: EventEmitter<any> = new EventEmitter();
  @Output() navigateToNavigation: EventEmitter<any> = new EventEmitter();


  public urlserver: string = ENV_PROD.urlApi;
  public hashkey: any = null;
  public listercommandes: any = [];
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public emitData: EventEmitter<any> = new EventEmitter;
  public isTimeline: boolean = true;
  public details: any;
  public Item: any;
  public LANGAGE: any = Francais;

  public bggreen: any = "#74b842";
  // public bgorange: any = "#274f8d";
  public bgorange: any = "rgba(39,79,141,0.55)";
  public bgred: any = "#357cac";
  public bggray: any = "#b3bccb";
  public bgpurple: any = "#74b842";


  constructor(
    private alertService: AlertService,
    private httpClient: HttpClient,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
  ) {
  }

  ngOnInit(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.spinner.show();
    setTimeout(() => { this.spinner.hide() }, 20000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu: [50, 70, 100],
      "order": [[3, 'desc']],
      responsive: true
    };
    this.getallcommandes();

  }

  ngOnChanges(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.spinner.show();
    setTimeout(() => {  this.spinner.hide()}, 20000);
    this.getallcommandes();
  }


  private getallcommandes() {
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'commande/getall',
      {headers: headers}
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listercommandes = res.data;
            console.log('--------',this.listercommandes.length);
            this.dtTigger.next();
          }
        });
  }








  /**
   * filterByDate
   */
  public filterByDate(item) {
    let dateSplte = item.split(',');
    this.getBy2date(dateSplte[0], dateSplte[1])
  }

  public async getBy2date(start, end) {
    this.spinner.show();
    setTimeout(() => { this.spinner.hide()}, 10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      let body = {
        startDate: start,
        endDate: end
      };
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      setTimeout(() => this.spinner.hide(), 6000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'commande/getByIntervalDate',
        JSON.stringify(body),
        {headers: headers}
      ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status === 200) {
              this.listercommandes = res.data;
              this.dtTigger.next()
            }
          });

    })
  }



  public preuvelivraison(item) {
    if(this.hashkey != null){
      const url = `https://dojj533egh.execute-api.eu-west-3.amazonaws.com/v1/${this.hashkey[0].accountID}/delivery-doc/${item.SalesId}?hash=${this.hashkey[0].hash}`;
      window.open(url, "_blank");
    }
  }

  public preuvelivraisontimeline(item) {
    if(item.isInsertedFacture){
      if(this.hashkey != null){
        const url = `https://dojj533egh.execute-api.eu-west-3.amazonaws.com/v1/${this.hashkey[0].accountID}/delivery-doc/${item.SalesId}?hash=${this.hashkey[0].hash}`;
        window.open(url, "_blank");
      }
    }
  }

  public suivrelivraison(item){
    const url = `https://track.georeference.fr/pro/applications/delivery/?key=e0fdc6ae51b423f02ff3b600ae7eb825&prompt_placeholder=Numero%20de%20commande%20&panel_align=br&color=80BA27&panel_scale=big&map=roadmap`;
    window.open(url, "_blank");

  }


  public formatEtatCommande(item) {

    if(item.isInsertedPreparepourenlevementDESADV){
      return this.LANGAGE.preparepourenlevement;
    }
    if(item.isInsertedEnpreparationORDRSP){
      return this.LANGAGE.enpreparation;
    }
    // livré
    if(item.isInsertedFacture){
      return this.LANGAGE.livreetfacture;
    }

    if( item.isEnroute)
      return this.LANGAGE.enroute;


  }


  public formatetat(item) {
    if(item.isInsertedShipment)//am commande
      return this.LANGAGE.commandeprete;
    else
      return this.LANGAGE.commandpartiellementprete;
  }




  public  formatcommandevalide(item){
    if(item.isInsertedEnpreparationORDRSP)
      return this.LANGAGE.commandvalide;
    else
      return this.LANGAGE.commandvalide;
  }



  public updateAffichage(value) {
    if(value == this.LANGAGE.timeline){
      this.isTimeline = true;
    } else{
      this.isTimeline = false;
    }
  }

  formatdate(date){
    return `${date.substr(6,2)}-${date.substr(4,2)}-${date.substr(0,4)} ${date.substr(8,2)}:${date.substr(10,2)}`
  }




  public async emitDetail(item: any) {
    this.emitData.emit(item);
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    setTimeout(() => this.spinner.hide(), 6000);
    this.httpClient.get<any>(ENV_PROD.urlApi + 'facture/detail/'+item.SalesId,
      {headers: headers}
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        async (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            if(res.data.contenu != null){
              const parsed = JSON.parse(res.data.contenu);
              this.details = parsed;
              this.Item = item;
              console.log("resdata",parsed);
              await <any>this.ngbModal.open(this.actiondetails)
            }

          }
        });
  }

  public  typeoff(item) {
    if(Array.isArray(item) == true){
      return true;
    }else {
      return false;
    }
  }
  public resetField() {
    this.ngbModal.dismissAll();
  }
}
