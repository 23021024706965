import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {ENV_PROD} from "../../environments/config-env";
import {throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private toast: ToastrService,
    private modal: NgbModal
  ) { }

  // --------- MODAL GESTION -----------------
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
  /**
   * openModal
   */
  public async openModal(modal) {
    await <any>this.modal.open(modal)
  }

  /**
   * openModalLg
   */
  public async openModalLg(modal) {
    await <any>this.modal.open(modal, { size: 'lg', animation: true })
  }

  /**
   * closeModal
   */
  public closeModal() {
    this.modal.dismissAll()
  }

  // --------- TOASTR GESTION -------------------------

  /**
   * success
   */
  public addSuccess() {
    this.toast.success(
      'Insertion réussie.',
      ENV_PROD.nom_application,
      { timeOut: 6000, positionClass: 'toast-bottom-right' }
    )
  }

  /**
   * updateSuccess
   */
  public updateSuccess() {
    this.toast.success(
      'Modification réussie.',
      ENV_PROD.nom_application,
      { timeOut: 6000, positionClass: 'toast-bottom-right' }
    )
  }

  /**
   * deleteSuccess
   */
  public deleteSuccess() {
    this.toast.success(
      'Suppression réussie.',
      ENV_PROD.nom_application,
      { timeOut: 6000, positionClass: 'toast-bottom-right' }
    )
  }

  /**
   * error
   */
  public error() {
    this.toast.error(
      'Une erreur est survenue. Veuillez réessayer ultérieurement.',
      ENV_PROD.nom_application,
      { timeOut: 6000, positionClass: 'toast-bottom-right' }
    )
  }

  /**
   * successMessage
message   */
  public successMessage(message) {
    this.toast.success(
      message,
      ENV_PROD.nom_application,
      { timeOut: 6000, positionClass: 'toast-bottom-right' }
    )
  }

  /**
   * errorMessage
   */
  public errorMessage(message) {
    this.toast.error(
      message,
      ENV_PROD.nom_application,
      { timeOut: 6000, positionClass: 'toast-bottom-right' }
    )
  }

  /**
   * reloadPage
   */
  public reloadPage() {
    this.toast.info(
      'Une erreur est survenue. Veuillez rafraîchir la page.',
      ENV_PROD.nom_application,
      { timeOut: 6000, positionClass: 'toast-bottom-right' }
    )
  }

  /**
   * emptyField
   */
  public emptyField(field) {
    this.toast.info(
      'Veuillez remplir le champ ' + field,
      ENV_PROD.nom_application,
      { timeOut: 6000, positionClass: 'toast-bottom-right' }
    )
  }
}
