import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { DatatableLanguage } from "../../../constant/french-datatable";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import {NgxSpinnerService} from "ngx-spinner";
import {ENV_PROD} from "../../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import {AlertService} from "../../../services/alert.service";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Francais} from "../../../../environments/Francais";
import {Anglais} from "../../../../environments/Anglais";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, OnChanges {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public email: any = "";
  public message: any = "";
  public LANGAGE: any = Francais;


  constructor(
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.email = localStorage.getItem('adminEmail');
  }

  ngOnChanges(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.email = localStorage.getItem('adminEmail');
  }





  public enregistrer(){
     this.message = this.message.trim();
     if(this.message === ""){
       alert(this.LANGAGE.lemessagenepourraitvide);
       return;
     }

      let body = {
        message: this.message,
        email: this.email,
      };
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'commercial/message',
        JSON.stringify(body),
      { headers: headers},
    ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
          this.resetField();
            if(res.status == 200)
              this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
        })

  }



   public resetField() {
    this.message = '';
  }




}
