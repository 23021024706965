
<div class="card-head">
  <button  class="btn btn-success btn-sm pull-right" (click)="openModal(actionentreprise)">
    <i class="fa fa-plus" aria-hidden="true"></i> {{LANGAGE.nouveau}}
  </button>
</div>

<div class="card-body">
  <div class="row">
    <!-- <table class="table"> -->
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th>{{LANGAGE.entreprise}}</th>
        <th>{{LANGAGE.adresse}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listentreprise" class="pointer-cl">
        <td (click)="emitDataentreprise(item)">{{item.nom}}</td>
        <td (click)="emitDataentreprise(item)">{{item.adresse}}</td>
        <td style="display: flex; flex-direction: row; align-items: center;">
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
          <button *ngIf="listentreprise.length>0"class="btn btn-sm btn-outline-info" style="margin-left: 10px; color: #1b3762; border-color: #1b3762;"  (click)="versgroupe(item.id)">
            <i class="fas fa-people-arrows"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #actionentreprise>
  <div class="modal-header">
    <h4  class="modal-title" style="font-size:20px; font-weight: bold" >{{LANGAGE.nouvelleentreprise}}</h4>
    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{LANGAGE.nom}}</label>
      <input type="text" class="form-control" name="entrepriseName" [(ngModel)]="entrepriseName">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.adresse}}</label>
      <input type="text" class="form-control" name="entrepriseAdress" [(ngModel)]="entrepriseAdress">
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info"  style="background-color: #1b3762; color:#fff;" (click)="addentreprise()"><i class="fa fa-plus-circle" aria-hidden="true"></i> {{LANGAGE.ajouter}}</button>
  </div>
</ng-template>


<ng-template #actionentreprise1>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold">{{LANGAGE.modificationentreprise}}</h4>

    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{LANGAGE.nom}}</label>
      <input type="text" class="form-control" name="entrepriseName" [(ngModel)]="entrepriseName">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.adresse}}</label>
      <input type="text" class="form-control" name="entrepriseAdress" [(ngModel)]="entrepriseAdress">
    </div>

  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-info"  style="background-color: #1b3762; color:#fff;" (click)="updateentreprise()"><i class="fa fa-check-circle" aria-hidden="true"></i> Enregister</button>
  </div>
</ng-template>

<ng-template #confirmDelete>
  <div class="card-body">
    {{LANGAGE.etesvoussurdevouloirsupprimer}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deleteentreprise()">{{LANGAGE.oui}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">{{LANGAGE.non}}</button>
  </div>
</ng-template>

