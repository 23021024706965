import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { AdminService } from "../../../services/admin.service";
import { DatatableLanguage } from "../../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as CryptoJS from 'crypto-js';
import {NgxSpinnerService} from "ngx-spinner";
import {ENV_PROD} from "../../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import {AlertService} from "../../../services/alert.service";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Francais} from "../../../../environments/Francais";
import {Anglais} from "../../../../environments/Anglais";


@Component({
  selector: 'app-hashkey',
  templateUrl: './hashkey.component.html',
  styleUrls: ['./hashkey.component.css']
})
export class HashkeyComponent implements OnInit, OnChanges {
  @ViewChild('actionadmin') actionadmin: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public listhashkey: any = [];
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public hashkeyId: any;
  public accountID: any = "";
  public hash: any = "";
  public LANGAGE: any = Francais;

  constructor(
    private httpClient: HttpClient,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.spinner.show();
    this.gethashkey()
  }

  ngOnChanges(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.loadhashkey()
  }


  public loadhashkey(): void {
    this.spinner.show();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.gethashkey()
    })
  }

  private gethashkey(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      ////'Content-Type': 'application/json',
      Accept: "application/json",
       Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'hashkey/get',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listhashkey = res.data;
            this.dtTigger.next()
          }
        });
  }

  /**
   * emitDataadmin
   */
  public async openmodalmodification(item: any) {
    this.emitData.emit(item);
    this.hashkeyId = item.id;
    this.accountID = item.accountID;
    this.hash = item.hash;
    await <any>this.ngbModal.open(this.actionadmin)
  }




   /**
   * updateadmin
   */
  public modificationhashkey(): void{
     this.accountID = this.accountID.trim();
     this.hash = this.hash.trim();
     if(this.accountID === ""){
       alert(this.LANGAGE.accountidnotempty);
       return;
     }
     if(this.hash === ""){
       alert(this.LANGAGE.hkeydoesnotempty);
       return;
     }

      let body = {
        id: this.hashkeyId,
        accountID: this.accountID,
        hash: this.hash,
      };
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'hashkey/update',
        JSON.stringify(body),
      { headers: headers},
    ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
          this.loadhashkey();
          this.resetField();
            if(res.status == 200)
              this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
        })

  }



   public resetField() {
    this.ngbModal.dismissAll();
    this.accountID = '';
    this.hash = '';
  }




}
