<div class="page-conf">
  <div class="card">
    <div class="card-header">
      <img src="../../assets/images/logo-mymatch-transparent1.png" class="logo"/>

    </div>
    <div class="card-body">
      <h1 class="card-text">{{message}}</h1>
    </div>
  </div>
</div>
