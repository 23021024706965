import { Component, OnInit, OnChanges, Output, EventEmitter,} from '@angular/core';
import { Subject } from "rxjs";
import { DatatableLanguage } from "../../constant/french-datatable";
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import {ENV_PROD} from "../../../environments/config-env";

import set = Reflect.set;
// import { Socket } from 'ngx-socket-io';
import * as io  from "socket.io-client";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, retry} from "rxjs/operators";
import {AlertService} from "../../services/alert.service";
import {Francais} from "../../../environments/Francais";
import {Anglais} from "../../../environments/Anglais";
const SocketEndpoint = ENV_PROD.urlApi1;


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit , OnChanges{


  public nomapplication : any = ENV_PROD.nom_application;
  public page : any;
  public title : any;
  public adminName : any;
  public adminType : any;
  public email: any;
  public LANGAGE: any = Francais;
  //
  public countArticles: number = 0;
  public countCommandes: number = 0;
  public countCommandesouvert: number = 0;
  public countLivraison: number = 0;
  public countMessages: number = 0;
  public articlespluscommande4: any = [];


  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  constructor(
    private alertService: AlertService,
    private httpClient: HttpClient,
    private router: Router,
    private locationStrategy: LocationStrategy,
    private spinner: NgxSpinnerService,
  ) {
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href)
    });
  }

  ngOnInit(): void {
    // this.socket = io(SocketEndpoint);
    // this.socket.on("notificationbadge_depuisserver", payload => {
    //   this.joueursignale = payload;
    // });
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }

    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu : [5, 10, 25],
      processing: true,
      language: DatatableLanguage.datatableFrench
    };
    this.adminName = localStorage.getItem('adminName');
    this.adminType = localStorage.getItem('adminType');


    if (localStorage.getItem('page') == 'home') {
      this.page = 'home';
      this.title = this.LANGAGE.tableaudebord;
    } else {// nataoko fa tsy voadinika tsara
      this.page = localStorage.getItem('page');
      let titlePage = '';
      let page = null;
      if(typeof this.page === 'object'){
        page = this.page.page;
        titlePage = this.page.titlePage;
      }
      switch (page) {
        case 'listerfactures':
          if(titlePage == '')
            titlePage = this.LANGAGE.facture;
          this.localsto(page, titlePage);
          break;
        //
        case 'listercommandes':
          if(titlePage == '')
            titlePage = this.LANGAGE.commande;
          this.localsto(page, titlePage);
          break;
        case 'CommandeEncoursLivraison':
          if(titlePage == '')
            titlePage = this.LANGAGE.encourslivraison;
          this.localsto(page, titlePage);
          break;
        case 'CommandeOuvertes':
          if(titlePage == '')
            titlePage =  this.LANGAGE.commandeouvertes;
          this.localsto(page, titlePage);
          break;
        case 'hashkey':
          if(titlePage == '')
            titlePage = this.LANGAGE.hkeyamazon;
          this.localsto(page, titlePage);
          break;
        case 'contact':
          if(titlePage == '')
            titlePage = this.LANGAGE.ecrireservicecommerciaux;
          this.localsto(page, titlePage);
          break;
        case 'message':
          if(titlePage == '')
            titlePage = this.LANGAGE.message;
          this.localsto(page, titlePage);
          break;
        case 'admin':
          if(titlePage == '')
            titlePage = this.LANGAGE.administration;
          this.localsto(page, titlePage);
          break;
        case 'entreprise':
          if(titlePage == '')
            titlePage = this.LANGAGE.entreprise;
          this.localsto(page, titlePage);
          break;
        case 'home':
          if(titlePage == '')
            titlePage = this.LANGAGE.tableaudebord;
          this.localsto(page, titlePage);
          break;

        default:
          break
      }
    }

    this.email = localStorage.getItem('adminEmail');
    this.initializeDashboard();



  }

  ngOnChanges(): void {
    this.spinner.show();
    setTimeout(()=>{this.spinner.hide()},12000);
  }



  public initializeDashboard() {


   this.getAllFactures();
   this.getAllCommandes();
   this.getAllCommandesouvert();
   this.getAllCommandesEncourslivraison();
   this.get4articleslespluscommande();




    this.spinner.hide()
  }




  public toPage(page_) {
    let page = page_ || {};
    let titlePage = '';
    if(typeof page_ === 'object'){
      page = page_.page;
      titlePage = page_.titlePage;
    }
    switch (page) {
      case 'listerfactures':
        if(titlePage == '')
          titlePage = this.LANGAGE.facture;
        this.localsto(page, titlePage);
      break;
      //
      case 'listercommandes':
        if(titlePage == '')
          titlePage = this.LANGAGE.commande;
        this.localsto(page, titlePage);
        break;

      case 'CommandeEncoursLivraison':
        if(titlePage == '')
          titlePage = this.LANGAGE.encourslivraison;
        this.localsto(page, titlePage);
        break;
      case 'CommandeOuvertes':
        if(titlePage == '')
          titlePage =  this.LANGAGE.commandeouvertes;
        this.localsto(page, titlePage);
        break;


      case 'hashkey':
        if(titlePage == '')
          titlePage = this.LANGAGE.hkeyamazon;
        this.localsto(page, titlePage);
        break;
      case 'contact':
        if(titlePage == '')
          titlePage = this.LANGAGE.ecrireservicecommerciaux;
        this.localsto(page, titlePage);
        break;
      case 'message':
        if(titlePage == '')
          titlePage = this.LANGAGE.message;
        this.localsto(page, titlePage);
        break;
        //

      case 'admin':
        if(titlePage == '')
          titlePage = this.LANGAGE.administration;
        this.localsto(page, titlePage);
        break;
      case 'entreprise':
        if(titlePage == '')
          titlePage = this.LANGAGE.entreprise;
        this.localsto(page, titlePage);
        break;
      case 'groupe':
        if(titlePage == '')
          titlePage =this.LANGAGE.groupe;
        this.localsto(page, titlePage);
        break;
      case 'facturepargroupe':
        if(titlePage == '')
          titlePage = this.LANGAGE.facturepargroupeid;
        this.localsto(page, titlePage);
        break;

      case 'home':
        if(titlePage == '')
          titlePage = this.LANGAGE.tableaudebord;
        this.localsto(page, titlePage);
        break;


    }
  }

  private localsto(page,titlePage){
    this.page = page;
    this.title = titlePage;
    localStorage.setItem('page', this.page);
    if (page == 'home') {
      this.initializeDashboard()
    }
  }

  private getAllFactures(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });

    this.httpClient.get<any>(ENV_PROD.urlApi + 'facture/getcount',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          if (res.status === 200) {
              this.countArticles = res.data;
          }
        })


  }


  private getAllCommandes(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });

    this.httpClient.get<any>(ENV_PROD.urlApi + 'commande/getcount',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          if (res.status === 200) {
            this.countCommandes = res.data;
          }
        })
  }



  private getAllCommandesouvert(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });

    this.httpClient.get<any>(ENV_PROD.urlApi + 'commande/getcountouvert',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          if (res.status === 200) {
            this.countCommandesouvert = res.data;
          }
        })
  }



  private get4articleslespluscommande(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });

    this.httpClient.get<any>(ENV_PROD.urlApi + 'articles/4pluscommande',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          if (res.status === 200) {
            this.articlespluscommande4 = res.data;
          }
        })
  }
  private getAllCommandesEncourslivraison(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });

    this.httpClient.get<any>(ENV_PROD.urlApi + 'commande/getcountencourslivraison',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          if (res.status === 200) {
            this.countLivraison = res.data;
          }
        })
  }


  private getCountMessageNonLu(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });

    this.httpClient.get<any>(ENV_PROD.urlApi + 'message/getcount',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          if (res.status === 200) {
            this.countMessages = res.data;
          }
        })


  }

  public sign_out(){
    localStorage.clear();
    this.router.navigateByUrl('');
  }



  public funcnavigateToNavigation(event) {
    console.log('aoeoe',event)
    this.toPage(event);
  }

  public about() {
    const url = `https://about.globalnet.be/fr/nous-contacter`;
    window.open(url, "_blank");
  }


  public toDocTechniques() {
    const url = `https://download.globalnet.be/`;
    window.open(url, "_blank");
  }





}
