import { Component, OnInit, Input, OnChanges } from '@angular/core';
import _ from "lodash";
import * as moment from 'moment';
@Component({
  selector: 'app-user-chart',
  templateUrl: './user-chart.component.html',
  styleUrls: ['./user-chart.component.css']
})
export class UserChartComponent implements OnInit, OnChanges {
  @Input() dataChart: any;
  @Input() titreChart: any;
  @Input() type: any;
  constructor() { }
  myChartMois: any;
  dataX: any[] = [];
  dataY: any[] =[];
  ngOnInit(): void {
  }

  ngOnChanges(): void{
    this.fomerDataUser();
  }

  async fomerDataUser () {
    if (this.dataChart) {
      this.dataY = [];
      this.dataX = [];
      const dateGroup = item => moment(this.type == 0 ? item?.createdAt: item?.voyages?.createdAt).format("DD/MM/YYYY");
      const dataChartOrder = _(this.dataChart).orderBy([dateGroup],['asc']).value();
      const resultH = await _(dataChartOrder).groupBy(dateGroup).map((value, key) => ({
        date: key,
        nbUser: value.length
      })).value();
      let nb = 0;
      resultH.forEach(data => {
        nb = nb + data.nbUser;
        this.dataY.push(nb);
        this.dataX.push(data.date);
      });
      this.initChart();
    }
  }

  initChart() {
    this.myChartMois = {
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        show: true,
        feature: {
          dataView: {show: true, readOnly: false},
          magicType: {show: true, type: ['line', 'bar']},
          restore: {show: true},
          saveAsImage: {show: true},
        }
      },
      calculable: true,
      dataZoom: [{
        type: 'inside'
      }, {
        type: 'slider'
      }],
      xAxis: [
        {
          type: 'category',
          data: this.dataX
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Livraison',
          type: 'bar',
          label: {
            show: true,
            position: 'top'
          },
          data: this.dataY,
          markLine: {
            data: [
              {type: 'average', name: ''}
            ]
          }
        }
      ]
    };
  }
}
