import { Component, OnInit, EventEmitter, ViewChild, OnChanges, Output } from '@angular/core';
import { AdminService } from "../../../services/admin.service";
import { DatatableLanguage } from "../../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as CryptoJS from 'crypto-js';
import {NgxSpinnerService} from "ngx-spinner";
import {ENV_PROD} from "../../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import {AlertService} from "../../../services/alert.service";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Francais} from "../../../../environments/Francais";
import {Anglais} from "../../../../environments/Anglais";


@Component({
  selector: 'app-modificationadmin',
  templateUrl: './modificationadmin.component.html',
  styleUrls: ['./modificationadmin.component.css']
})
export class ModificationadminComponent implements OnInit, OnChanges {
  @ViewChild('actionadmin') actionadmin: any;
  @ViewChild('actionadmin1') actionadmin1: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @Output() navigateToNavigation: EventEmitter<any> = new EventEmitter();


  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public listadmin: any = [];
  public tokenFromUI: string = "0123456789123456";
  public encrypted: string;
  public decrypted: string;
  // option module datatable
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public adminId: any;
  public adminName: any = "";
  public adminEmail: any = "";
  public adminAdress: any;
  public itemPassword: any = "";
  public nouveauPassword: any = "";
  public adminPassword: any = "";
  public adminconfirmPassword: any = "";
  public adminType: any;
  // check action
  public isUpdateWithPassword: boolean = false;
  public listentreprise: any = [];
  public entrepriseId: any = "";
  public LANGAGE: any = Francais;

  constructor(
    private httpClient: HttpClient,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.adminType = localStorage.getItem('adminType');
    this.entrepriseId = localStorage.getItem('entrepriseId');

    this.spinner.show();
    this.getalladmin();
  }

  ngOnChanges(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.loadadmin()
  }

  /**
   * loadadmin
   */
  public loadadmin(): void {
    this.spinner.show();
    this.adminType = localStorage.getItem('adminType');
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.decryptUsingAES256(this.adminPassword);
      this.getalladmin()
    })
  }

  private getalladmin(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      ////'Content-Type': 'application/json',
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    // this.httpClient.post<any>(ENV_PROD.urlApi + 'admins/getAll', {}).subscribe(
    this.httpClient.get<any>(ENV_PROD.urlApi + 'admins/getAll',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listadmin = res.data;
            console.log('list admin', res.data);
            if(this.adminType != "SUPERADMIN")
            this.dtTigger.next()
          }
        });

    //get all entreprise
    if(this.adminType == "SUPERADMIN")
      this.httpClient.get<any>(ENV_PROD.urlApi + 'entreprise/getAll',
        { headers: headers }
      ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          async (res) => {
            this.spinner.hide();
            if (res.status === 200) {
              console.log('le status', res)
              this.listentreprise = res.data;
              let newListesEntreprises = [];
              if(res.data.length>0)
                for await(let item of this.listadmin){
                  if(item.type == "SUPERADMIN"){
                    await newListesEntreprises.push(item)
                  }
                  for await (let item1 of res.data){
                    if(item.entrepriseId == item1.id){
                      let entr = {...item, nomEntreprise: item1.nom};
                      await newListesEntreprises.push(entr)
                    }
                  }
                }
              console.log('newListesEntreprises',JSON.stringify(newListesEntreprises));

              this.listadmin = newListesEntreprises;
              this.dtTigger.next()


            }
          });
  }

  /**
   * emitDataadmin
   */
  public async emitDataadmin(item: any) {
    this.emitData.emit(item);
    this.adminId = item.id;
    this.entrepriseId = item.entrepriseId;
    this.adminName = item.nom;
    this.adminEmail = item.email;
    this.adminAdress = item.adresse;
    this.itemPassword = this.decryptUsingAES256(item.password);
    //this.adminPassword = this.decryptUsingAES256(item.password);
    //this.adminType = item.type;
    await <any>this.ngbModal.open(this.actionadmin1)
  }

  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.adminId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

  /**
   * addadmin
   */
  public addadmin() {


    if(this.adminType == "SUPERADMIN" && this.listentreprise.length === 0){
      alert(this.LANGAGE.veuillezajouterentreprise);
      return;
    }
    if(this.adminName === ""){
      alert(this.LANGAGE.lenomnepourraitpasvide);
      return;
    }
    if(this.adminEmail === ""){
      alert(this.LANGAGE.emailnepourraitpasvide);
      return;
    }
    if(this.adminPassword === ""){
      alert(this.LANGAGE.motdepassnepourraitpasvide);
      return;
    }
    if(this.adminPassword.trim().length <= 5){
      alert(this.LANGAGE.motdepassedoitplus6);
      return;
    }
    if(this.adminPassword !== this.adminconfirmPassword){
      alert(this.LANGAGE.motdepassenecorrespond);
      return;
    }

    let body = {
      nom: this.adminName,
      email: this.adminEmail,
      adresse: this.adminAdress,
      password: this.adminPassword,
      entrepriseId: this.entrepriseId,
    };
    if(this.entrepriseId == 'ADMIN')
      body['type'] = 'SUPERADMIN';
    else
      body['type'] = 'ADMIN';


    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    this.httpClient.post<any>(ENV_PROD.urlApi + 'admins/add',
      JSON.stringify(body),
      { headers: headers, }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if(res.status && res.status === 404 && res.message ){
            alert(ENV_PROD.nom_application + " \n " + res.message);
          }
          this.loadadmin();
          this.resetField();
        });
  }

  /**
   * updateadmin
   */
  public updateadmin(): void{
    this.adminName = this.adminName.trim();
    this.nouveauPassword = this.nouveauPassword.trim();
    this.adminconfirmPassword = this.adminconfirmPassword.trim();
    if(this.adminName === ""){
      alert(this.LANGAGE.nomnepourraitpasvide);
      return;
    }
    if(this.adminEmail === ""){
      alert(this.LANGAGE.emailnepourraitpasvide);
      return;
    }

    if(this.isUpdateWithPassword === false){
      if(this.adminPassword === ""){
        alert(this.LANGAGE.veuillezinserermotdepassepourvalidermodif);
        return;
      }
      if(this.adminPassword !== "" && this.adminPassword !== this.itemPassword){
        alert(this.LANGAGE.motdepassepourvalidermodifnecorrespond);
        return;
      }
      let body = {
        id: this.adminId,
        nom: this.adminName,
        email: this.adminEmail,
        adresse: this.adminAdress,
        password: this.encryptUsingAES256(this.itemPassword),
        entrepriseId: this.entrepriseId

      };
      if(this.entrepriseId == 'ADMIN')
        body['type'] = 'SUPERADMIN';
      else
        body['type'] = 'ADMIN';
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'admins/update',
        JSON.stringify(body),
        { headers: headers},
      ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.loadadmin();
            this.resetField();
            if(res.status != 200)
              this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});

          })
    }else{
      if(this.adminPassword === ""){
        alert(this.LANGAGE.veuillezinsererancienpass);
        return;
      }
      if(this.nouveauPassword === ""){
        alert(this.LANGAGE.veuillezinserernouveaupass);
        return;
      }
      if(this.adminPassword.trim().length <= 5){
        alert(this.LANGAGE.motdepassedoitplus6);
        return;
      }
      if(this.adminconfirmPassword === ""){
        alert(this.LANGAGE.veuillezconfirmerpass);
        return;
      }
      if(this.adminPassword !== "" && this.adminPassword !== this.itemPassword){
        alert(this.LANGAGE.ancienmotdepassenecorrespond);
        return;
      }
      if(this.adminconfirmPassword !== "" && this.nouveauPassword !== "" && this.adminconfirmPassword !== this.nouveauPassword){
        alert(this.LANGAGE.newpassandconfirmnotsame);
        return;
      }

      let body = {
        id: this.adminId,
        nom: this.adminName,
        email: this.adminEmail,
        adresse: this.adminAdress,
        password: this.encryptUsingAES256(this.nouveauPassword),
      };
      if(this.entrepriseId == 'ADMIN')
        body['type'] = 'SUPERADMIN';
      else
        body['type'] = 'ADMIN';
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'admins/update',
        JSON.stringify(body),
        { headers: headers },
      ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.loadadmin();
            this.resetField();
            if(res.status != 200)
              this.toastr.success(res.message, ENV_PROD.nom_application ,{timeOut: 6000,positionClass: 'toast-bottom-right',});
          })
    }
  }

  /**
   * deleteadmin
   */
  public deleteadmin() {
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'admins/delete/'+this.adminId,
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.loadadmin();
          this.resetField();
          if(res.status != 200){
            this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
          }
        });

  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public resetField() {
    this.ngbModal.dismissAll();
    this.adminId = '';
    this.adminName = '';
    this.adminEmail = '';
    this.adminAdress = '';
    this.itemPassword = '';
    this.adminPassword = '';
    this.nouveauPassword = '';
    this.adminconfirmPassword = '';
    this.isUpdateWithPassword = false;
  }

  public  encryptUsingAES256(code) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(code), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    return  this.encrypted
  }

  public decryptUsingAES256(code) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    this.decrypted = CryptoJS.AES.decrypt(
      code, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
    return this.decrypted.slice(1,this.decrypted.length - 1)
  }


  public ismodifierpassword(event) {
    this.isUpdateWithPassword = event.target.checked;
  }

  public versgroupe(event) {
    console.log('evvvv',event)
    if(this.adminType == "SUPERADMIN"  && event.type != "SUPERADMIN" ){
      localStorage.setItem('itementreprise', event.entrepriseId);
    }else if(this.adminType == "SUPERADMIN"  && event.type == "SUPERADMIN"){
      localStorage.setItem('itemtypesuperadmin', event.type);
    }
    this.navigateToNavigation.emit({page:"groupe", titlePage:"Groupe"})
  }


}
