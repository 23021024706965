import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ENV_PROD } from '../../environments/config-env'
import { Admin } from "../constant/admin"
import { retry, catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public apiUrl = ENV_PROD.urlApi;
  constructor(
    private httpClient: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  };


  public validateEMail = (e_mail_) => {
    const e_mail = e_mail_.trim();
    if(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e_mail
      )){
      return true;
    } else {
      return false;
    }
  };

  public validatePhoneNumber = (phone_number) => {
    if (
      /^(\+{0,})(\s{0,1})(\d{0,})(\s{0,1})([(]{1}\d{1,3}[)]{0,}){0,}((\s|-|\d){9,15})/.test(
        phone_number
      )) {
      return true;
    } else {
      return false;
    }
  };



  public gettokenbyid(adminId): Observable<Admin> {
    return this.httpClient.get<Admin>(
      this.apiUrl + 'admins/gettokenbyid/'+adminId,
      this.httpOptions
    )
      .pipe(retry(1), catchError(this.handleError))
  }






  public login(data): Observable<any> {
    return this.httpClient.post<any>(
      this.apiUrl + 'admins/login',
      JSON.stringify(data),
      this.httpOptions
    ).pipe(retry(1), catchError(this.handleError))
  }

  // Error handling
  public handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(['Erreur HTTP', errorMessage]);
    return throwError(errorMessage);
  }
}
