
export const Francais = {
  LANGAGE:'Fr',
  connectezvous:'Connectez-vous',
  connexion:'Connexion',
  recupererpassword:'Récuperer le mot de passe?',
  utilisateurnecessaire:"L'utilisateur est necessaire",
  motdepassenecessaire:"Le mot de passe est necessaire",
  leloginmotdepasseincorrect:"Le login ou le mot de passe est incorrect !",
  emailinexistant:"L'email que vous avez inséré n'existe pas dans notre répertoire.",

  tableaudebord:'Tableau de bord',
  voulezvousvraimentdeconnecter:'Voulez-vous vraiment vous déconnecter?',
  oui:'Oui',
  non: 'Non',
  Recuperer: 'Récuperer',
  superadmin: 'SUPER ADMIN : ',
  deconnexion : 'Déconnexion',
  facture:'Factures',
  listerfactures: 'Lister les factures',
  commande: 'Commandes',
  listercommande: 'Lister les commandes',
  parametre: 'Paramètres',
  entreprise: 'Entreprise',
  contact: 'Contact',
  message: 'Messages',
  about: 'About',
  detail: 'Détail',
  toutescommande: 'toutes les commandes',
  commandeouvertes: 'Commandes ouvertes',
  encourslivraison: 'En cours de livraison',
  articleslepluscommande: '4 articles le plus commandé',
  hkeyamazon: 'HASH KEY AMAZON',
  ecrireservicecommerciaux: 'Ecrire aux sérvices commerciaux',
  administration: 'Administration',
  facturepargroupeid: 'FACTURE PAR GROUPE ID',

  timeline: 'Timeline',
  tableau: 'Tableau',
  commande1: 'COMMANDE',
  date: 'DATE',
  livraison: 'LIVRAISON',
  facturation: 'FACTURATION',
  montant: 'MONTANT',
  noteenvoi: 'NOTE D\'ENVOI',
  preuvelivraison: 'PREUVE DE LIVRAISON',
  etatcommande: 'ETAT DE LA COMMANDE',
  aucunedonne: 'Aucune donnée',
  commande2: 'Commande',
  montant1: 'Montant',
  etat: 'ETAT',
  commandpartiellementprete: 'Commande partièlement prête',
  enroute: 'En route',
  enattentedateexpedition: 'En attente de la date d\'expédition.',
  suivi: 'SUIVI',
  suivre: 'Suivre',
  telecharger: 'Télécharger',
  facture1: 'Facture',
  commandefacture: 'Commande facturé',
  factureenvoye: 'Facture envoyée',
  preparepourenlevement: "Préparée pour enlèvement",
  enpreparation: "en préparation",
  livreetfacture:  "Livrée et facturé",
  commandeprete:  "Commande prête",
  commandvalide:  'Commande validée',

  groupeid : 'GROUPE ID',
  compteclient : 'COMPTE CLIENT',
  referenceclient : 'REFERENCE CLIENT',
  numerofacture : 'NUMERO DE FACTURE',
  datecommande : 'DATE DE COMMANDE',
  montantHt : 'MONTANT HT',
  montantTVA : 'MONTANT DE LA TVA',
  montantTTC : 'MONTANT TTC',

  etesvoussurdevouloirsupprimer: 'Etes-vous sûr de vouloir supprimer?',
  numerocommande: 'Numero de commande:',
  numerofacture1: 'Numero de facture:',
  client: 'Client:',
  adresse: 'Adresse:',
  livreur: 'Livreur:',
  article: 'Article',
  articles: 'Articles',

  exporterexcel: 'Exporter excel',

  email:'E-mail',
  message1:'Message',
  envoyer:'Envoyer',
  lemessagenepourraitvide:"Le message pourrait-être pas vide.",

  nouveau:"Nouveau",
  nouvelleentreprise:"Nouvelle entreprise",
  nom:"Nom",
  ajouter:"Ajouter",
  modificationentreprise:"Modification de l'entreprise",
  nomnepourraitpasvide:"Le Nom ne pourrait-être pas vide.",
  groupeid1 : 'Groupe id',
  nomEntreprise: 'Nom entreprise',
  invoiceaccountAX: 'invoiceaccountAX',
  InvoiceName: 'InvoiceName',
  CustomerReference: 'CustomerReference',
  nouveaugroupe: 'Nouveau groupe',
  modificationgroupe: 'Modification groupe',
  InvoiceAddress: 'InvoiceAddress',
  enregistrer: 'Enregistrer',
  veuillezchoisirentreprise: "Veuillez choisir l'entreprise.",
  veuillezajoutergroupid: "Veuillez ajouter le groupe ID",
  veuillezajouterinvoiceorcustomer: "Veuillez ajouter d'invoiceaccountAX ou de CustomerReference",
  legroupeidnepourraitetrepasvide: "Le groupeId ne pourrait-être pas vide.",

  accountHkey: "accountID",
  hash: "Hash",
  hkey: "Hash Key",
  modificationhkey: "Modification hashkey amazon",
  accountidnotempty: "L'accountID pourrait-être pas vide.",
  hkeydoesnotempty: "Le hash ne pourrait-être pas vide.",
  date1: "Date",
  repondre: "Repondre",
  objetemail: "Objet",
  groupe: "Groupe",
  type: "Type",
  nouveauadmin: "Nouveau admin",
  admin: "Admin",
  motdepasse: "Mot de passe",
  confirmPassword: "Confirmer le mot de passe",
  modificationadmin: "Modification admin",
  modifieraussilepass: "Modifier aussi le mot de passe",
  veuillezajouterentreprise: "Veuillez ajouter d'entreprise d'abord",
  emailnepourraitpasvide: "L'e-mail ne pourrait-être pas vide.",
  motdepassnepourraitpasvide: "Le mot de passe ne pourrait-être pas vide.",
  motdepassedoitplus6: "Le mot de passe doit comporter au moins 6 caractères.",
  motdepassenecorrespond: "Le mot de passe et la confirmation du mot de passe n'est pas identique.",
  veuillezinserermotdepassepourvalidermodif: "Veuillez insérer votre mot de passe pour valider la modification.",
  motdepassepourvalidermodifnecorrespond: "Le mot de passe pour valider la modification ne correspond pas.",
  veuillezinsererancienpass: "Veuillez insérer votre ancien mot de passe.",
  veuillezinserernouveaupass: "Veuillez insérer votre nouveau mot de passe.",
  veuillezconfirmerpass: "Veuillez confirmer votre nouveau mot de passe.",
  ancienmotdepassenecorrespond: "Votre ancien mot de passe ne correspond pas",
  newpassandconfirmnotsame: "Le nouveau mot de passe et la confirmation du nouveau mot de passe ne correspond pas.",


  //888888888888888888888888888888888888//
  subject: "Reinitialisation mot de passe sur Portail Client",
  nouveaumotdepasseenvoye: "un nouveau mot de passe PROVISOIRE a été envoyé  dans votre adresse email.",
  erreurdenvoimail: "une erreur s'est produit lors de l'envoie de mail",
  textemail : `Salut $nom  voici vos nouveaux identifiants Portail client, login : $email Mot de passe : $newpass Vous pouvez modifier ce mot de passe dans l'éspace d'administration, lorsque vous êtes connecté dans le back office.`,
  doctechniques: "Documents techniques",
};
