import {Component, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import { DatatableLanguage } from "../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { Time } from "@angular/common";
import set = Reflect.set;
import {AdminService} from "../../services/admin.service";
import {ENV_PROD} from "../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {AlertService} from "../../services/alert.service";
import {Francais} from "../../../environments/Francais";
import {Anglais} from "../../../environments/Anglais";

@Component({
  selector: 'app-FacturesParGroupe',
  templateUrl: './FacturesParGroupe.component.html',
  styleUrls: ['./FacturesParGroupe.component.css']
})
export class facturesParGroupeComponent implements OnInit {


  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild('actiondetails') actiondetails: any;

  @Output() navigateToAfficherJoueur: EventEmitter<any> = new EventEmitter();
  @Output() navigateToNavigation: EventEmitter<any> = new EventEmitter();


  public urlserver: string = ENV_PROD.urlApi;
  public facturesId: any = null;
  public listerfactures: any = [];
  public details: any;
  public Item: any;
  public idgroupe: any = null;
  public dtOptions: any = {};
  public dtTigger = new Subject();
  public emitData: EventEmitter<any> = new EventEmitter;
  public LANGAGE: any = Francais;

  constructor(
    private alertService: AlertService,
    private httpClient: HttpClient,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide()
    }, 10000);
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu: [50, 70, 100],
      "order": [[3, 'desc']],
      responsive: true
    };
    this.idgroupe =  localStorage.getItem('groupeId');
    this.getallfactures()

  }

  ngOnChanges(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide()
    }, 10000);
    this.idgroupe =  localStorage.getItem('groupeId');
    this.getallfactures()
  }


  private getallfactures() {
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    console.log('this id groupe', this.idgroupe)
    this.httpClient.get<any>(ENV_PROD.urlApi + 'facture/getall/'+this.idgroupe,
      {headers: headers}
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listerfactures = res.data;
            this.dtTigger.next();
          }
        });
  }

  /**
   * loadFactures
   */
  public loadFactures() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide()
    }, 10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.getallfactures();
    })
  }


  public async activedesactive(id, event) {
    this.spinner.show();
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    setTimeout(() => this.spinner.hide(), 6000);
    this.httpClient.get<any>(ENV_PROD.urlApi + 'users/activedesactive/' + id + '/' + event.target.checked,
      {headers: headers}
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listerfactures = res.data;
          }
        });
  }


  /**
   * filterByDate
   */
  public filterByDate(item) {
    let dateSplte = item.split(',');
    this.getBy2date(dateSplte[0], dateSplte[1])
  }

  public async getBy2date(start, end) {
    this.spinner.show();
    setTimeout(() => { this.spinner.hide()}, 10000);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      let body = {
        startDate: start,
        endDate: end
      };
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      setTimeout(() => this.spinner.hide(), 6000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'facture/getByIntervalDate/'+this.idgroupe,
        JSON.stringify(body),
        {headers: headers}
      ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status === 200) {
              this.listerfactures = res.data;
              this.dtTigger.next()
            }
          });

    })
  }


  /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.facturesId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }


  /**
   * delete
   */
  public async delete() {
    this.spinner.show();
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    setTimeout(() => this.spinner.hide(), 6000);
    this.httpClient.get<any>(ENV_PROD.urlApi + 'facture/delete/' + this.facturesId,
      {headers: headers}
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.loadFactures();
            this.resetField();
            this.spinner.hide();
          }
        });
  }

  public resetField() {
    this.ngbModal.dismissAll();
    this.facturesId = '';
  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

  // GEstion field data
  public closeModal() {
    this.ngbModal.dismissAll()
  }




  generateExcel(){
    this.spinner.show();
    setTimeout(()=>this.spinner.hide(),10000);
    let id_E = "";
    let body = {
      listerfacturesExcel:this.listerfactures,
    };

    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    setTimeout(() => this.spinner.hide(), 6000);
    this.httpClient.post<any>(ENV_PROD.urlApi + 'facture/generatexls/'+this.idgroupe,
      JSON.stringify(body),
      {headers: headers}
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = res.url;
            document.body.appendChild(a);
            a.click();
            this.resetField();
            this.spinner.hide();
            document.body.removeChild(a);
          }
        });
  }



  public async emitDetail(item: any) {
    this.emitData.emit(item);
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    setTimeout(() => this.spinner.hide(), 6000);
    this.httpClient.get<any>(ENV_PROD.urlApi + 'facture/detail/'+item.numerocommande,
      {headers: headers}
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        async (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            if(res.data.contenu != null){
              const parsed = JSON.parse(res.data.contenu);
              this.details = parsed;
              this.Item = item;
              console.log("resdata",parsed);
              await <any>this.ngbModal.open(this.actiondetails)
            }

          }
        });
  }


  public  typeoff(item) {
    if(Array.isArray(item) == true){
      return true;
    }else {
      return false;
    }
  }






}
