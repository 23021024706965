

export const Anglais = {
  LANGAGE:'En',
  connectezvous:'Log-in',
  connexion:'connection',
  recupererpassword:'Recover password?',
  utilisateurnecessaire:"Username is required",
  motdepassenecessaire:"Password is required",
  leloginmotdepasseincorrect:"The login or password is incorrect!",
  emailinexistant:"The email you inserted does not exist in our directory.",

  tableaudebord:'Dashboard',
  voulezvousvraimentdeconnecter:'Are you sure you want to log out?',
  oui:'Yes',
  non: 'No',
  Recuperer: 'Retrieve',
  superadmin: 'SUPER ADMIN : ',
  deconnexion : 'Logout',
  facture:'Invoices',
  listerfactures: 'List invoices',
  commande: 'Orders',
  listercommande: 'List commands',
  parametre: 'Settings',
  entreprise: 'Company',
  contact: 'Contact',
  message: 'Posts',
  about: 'About',
  detail: 'Detail',
  toutescommande: 'all orders',
  commandeouvertes: 'Open orders',
  encourslivraison: 'Out for delivery',
  articleslepluscommande: '4 most ordered items',
  hkeyamazon: 'HASH KEY AMAZON',
  ecrireservicecommerciaux: 'Write to commercial services',
  administration: 'Administration',
  facturepargroupeid: 'INVOICE BY GROUP ID',

  timeline: 'Timeline',
  tableau: 'Table',
  commande1: 'ORDERED',
  date: 'DATE',
  livraison: 'DELIVERY',
  facturation: 'BILLING',
  montant: 'AMOUNT',
  noteenvoi: 'DELIVERY NOTE',
  preuvelivraison: 'PROOF OF DELIVERY',
  etatcommande: 'ORDER STATUS',
  aucunedonne: 'Empty data',
  commande2: 'Ordered',
  montant1: 'Amount',
  etat: 'STATE',
  commandpartiellementprete: 'Order partially ready',
  enroute: 'On the way',
  enattentedateexpedition: 'Awaiting shipping date.',
  suivi: 'followed',
  suivre: 'Follow',
  telecharger: 'Download',
  facture1: 'Invoice',
  commandefacture: 'Order invoiced',
  factureenvoye: 'Invoice sent',
  preparepourenlevement: "Prepared for pickup",
  enpreparation: "in preparation",
  livreetfacture:  "Delivered and invoiced",
  commandeprete:  "Order ready",
  commandvalide:  'Order validated',

  groupeid : 'GROUPE ID',
  compteclient : 'CUSTOMER ACCOUNT',
  referenceclient : 'CLIENT REFERENCE',
  numerofacture : 'BILL NUMBER',
  datecommande : 'ORDER DATE',
  montantHt : 'AMOUNT excluding VAT',
  montantTVA : 'AMOUNT OF VAT',
  montantTTC : 'AMOUNT including VAT',

  etesvoussurdevouloirsupprimer: 'Are you sure you want to delete?',
  numerocommande: 'Order number:',
  numerofacture1: 'Bill number:',
  client: 'Customer:',
  adresse: 'Adress:',
  livreur: 'delivery man:',
  article: 'Article',
  articles: 'Items',

  exporterexcel: 'Exporter excel',

  email:'E-mail',
  message1:'Message',
  envoyer:'Send',
  lemessagenepourraitvide:"The message might not be empty.",

  nouveau:"New",
  nouvelleentreprise:"New business",
  nom:"Name",
  ajouter:"Add",
  modificationentreprise:"Company change",
  nomnepourraitpasvide:"The Name could not be empty.",
  groupeid1 : 'Groupe id',
  nomEntreprise: 'Company name',
  invoiceaccountAX: 'invoiceaccountAX',
  InvoiceName: 'InvoiceName',
  CustomerReference: 'CustomerReference',
  nouveaugroupe: 'New group',
  modificationgroupe: 'Modification groupe',
  InvoiceAddress: 'InvoiceAddress',
  enregistrer: 'Enregistrer',
  veuillezchoisirentreprise: "Please choose the company.",
  veuillezajoutergroupid: "Please add group ID",
  veuillezajouterinvoiceorcustomer: "Please add invoiceaccountAX or CustomerReference",
  legroupeidnepourraitetrepasvide: "GroupId may not be empty.",

  accountHkey: "accountID",
  hash: "Hash",
  modificationhkey: "Amendment hashkey amazon",
  accountidnotempty: "L'accountID doesn't empty.",
  hkeydoesnotempty: "Hash doesn't empty.",
  date1: "Date",
  repondre: "Answer",
  objetemail: "Object",
  groupe: "Group",
  type: "Type",
  nouveauadmin: "New admin",
  admin: "Admin",
  motdepasse: "Password",
  confirmPassword: "Confirm new password",
  modificationadmin: "Edit admin",
  modifieraussilepass: "Also change the password",
  veuillezajouterentreprise: "Please add company first",
  lenomnepourraitpasvide: "The Name could not be empty.",
  emailnepourraitpasvide: "The email might not be empty.",
  motdepassnepourraitpasvide: "The password might not be empty.",
  motdepassedoitplus6: "The password must contain at least 6 characters.",
  motdepassenecorrespond: "Password and confirmation password is not the same.",
  veuillezinserermotdepassepourvalidermodif: "Please insert your password to validate the modification.",
  motdepassepourvalidermodifnecorrespond: "The password to validate the modification does not match.",
  veuillezinsererancienpass: "Please insert your old password.",
  veuillezinserernouveaupass: "Please insert your new password.",
  veuillezconfirmerpass: "Please confirm your new password.",
  ancienmotdepassenecorrespond: "Your old password does not match",
  newpassandconfirmnotsame: "The new password and the confirmation of the new password do not match.",

  //888888888888888888888888888888888888//
  subject: "Customer Portal Password Reset",
  nouveaumotdepasseenvoye: "a new TEMPORARY password has been sent to your email address.",
  erreurdenvoimail: "an error occurred while sending mail",
  textemail : `Hi $name here are your new Portail client identifiers, login: $email Password: $newpass You can change this password in the administration area, when you are logged into the back office.`,
  doctechniques: "Technical documents",

};
