import {Component, OnInit, EventEmitter, ViewChild, OnChanges, Output} from '@angular/core';
import { DatatableLanguage } from "../../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as CryptoJS from 'crypto-js';
import {NgxSpinnerService} from "ngx-spinner";
import {ENV_PROD} from "../../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import {AlertService} from "../../../services/alert.service";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Francais} from "../../../../environments/Francais";
import {Anglais} from "../../../../environments/Anglais";

@Component({
  selector: 'app-entreprise',
  templateUrl: './entreprise.component.html',
  styleUrls: ['./entreprise.component.css']
})
export class Entreprise implements OnInit, OnChanges {
  @ViewChild('actionentreprise') actionentreprise: any;
  @ViewChild('actionentreprise1') actionentreprise1: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @Output() navigateToNavigation: EventEmitter<any> = new EventEmitter();

  public listentreprise: any = [];
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public entrepriseId: any;
  public entrepriseName: any = "";
  public entrepriseAdress: any;
  public adminType: any;
  public LANGAGE: any = Francais;

  // check action

  constructor(
    private httpClient: HttpClient,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.spinner.show();

    this.getallentreprise();
  }

  ngOnChanges(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.loadentreprise()
  }

  /**
   * loadentreprise
   */
  public loadentreprise(): void {
    this.spinner.show();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.getallentreprise()
    })
  }

  private getallentreprise(){
    this.adminType = localStorage.getItem('adminType');

    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      ////'Content-Type': 'application/json',
      Accept: "application/json",
       Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'entreprise/getAll',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status === 200) {
            this.listentreprise = res.data;
            this.dtTigger.next()
          }
        });
  }

  /**
   * emitDataentreprise
   */
  public async emitDataentreprise(item: any) {
    this.emitData.emit(item);
    this.entrepriseId = item.id;
    this.entrepriseName = item.nom;
    this.entrepriseAdress = item.adresse;
    await <any>this.ngbModal.open(this.actionentreprise1)
  }

   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.entrepriseId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

   /**
   * addentreprise
   */
  public addentreprise() {

     if(this.entrepriseName === ""){
       alert(this.LANGAGE.nomnepourraitpasvide);
       return;
     }

    let body = {
      nom: this.entrepriseName,
      adresse: this.entrepriseAdress,
    };
     const token = localStorage.getItem('userToken');
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       Accept: "application/json",
       Authorization: "Bearer " + token
     });
     this.spinner.show();
     setTimeout(()=>this.spinner.hide(),10000);
     this.httpClient.post<any>(ENV_PROD.urlApi + 'entreprise/add',
       JSON.stringify(body),
       { headers: headers, }
   ).pipe(retry(1), catchError(this.alertService.handleError))
       .subscribe(
         (res) => {
           this.spinner.hide();
           if(res.status && res.status === 404 && res.message ){
             alert(ENV_PROD.nom_application + " \n " + res.message);
           }
           this.loadentreprise();
           this.resetField();
         });
  }

   /**
   * updateentreprise
   */
  public updateentreprise(): void{
     this.entrepriseName = this.entrepriseName.trim();
     if(this.entrepriseName === ""){
       alert(this.LANGAGE.nomnepourraitpasvide);
       return;
     }

      let body = {
        id: this.entrepriseId,
        nom: this.entrepriseName,
        adresse: this.entrepriseAdress,
      };
      const token = localStorage.getItem('userToken');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: "Bearer " + token
      });
      this.spinner.show();
      setTimeout(()=>this.spinner.hide(),10000);
      this.httpClient.post<any>(ENV_PROD.urlApi + 'entreprise/update',
        JSON.stringify(body),
      { headers: headers},
    ).pipe(retry(1), catchError(this.alertService.handleError))
        .subscribe(
          (res) => {
            this.spinner.hide();
          this.loadentreprise();
          this.resetField();
            if(res.status != 200)
              this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});

        })

  }

  /**
   * deleteentreprise
   */
  public deleteentreprise() {
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'entreprise/delete/'+this.entrepriseId,
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.loadentreprise();
          this.resetField();
          if(res.status != 200){
            this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
          }
        });

  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }

   // GEstion field data
   public resetField() {
    this.ngbModal.dismissAll();
    this.entrepriseName = '';
    this.entrepriseAdress = '';
  }

  public versgroupe(event) {
    console.log('evvvv',event)
    if(this.adminType == "SUPERADMIN")
      localStorage.setItem('itementreprise', event);
    this.navigateToNavigation.emit({page:"groupe", titlePage:"Groupe"})
  }


}
