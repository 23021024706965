<div class="hold-transition sidebar-mini layout-fixed">
  <div class="wrapper">

    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
        </li>
      </ul>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a (click)="toPage('admin')" class="nav-link">
            {{this.adminType == 'SUPERADMIN'? LANGAGE.superadmin +  this.email :  this.email}}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" data-target=".bd-example-modal-sm">
            <i class="fas fa-power-off"></i> {{LANGAGE.deconnexion}}
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-5" style="background-color: #1b3762">
<!--       Brand Logo-->
      <a (click)="toPage('home')" class="brand-link pointer-pt">
        <img src="../../../assets/images/logo-mymatch-transparent.png" alt="Logo" class="brand-image img-circle elevation-3"
             style="opacity: .8">
        <span class="brand-text font-weight-light" (click)="toPage('home')">{{nomapplication}}</span>
      </a>
      <div class="sidebar">

        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">

              <a _ngcontent-dpb-c106="" class="nav-link">
                <i class="fas fa-file-invoice nav-icon" style="color: #58b83b;"></i>
                <p _ngcontent-dpb-c106=""   style="color: #74b842;">
                  {{LANGAGE.facture}}
                </p>
              </a>
              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">

                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a _ngcontent-dpb-c106="" (click)="toPage('listerfactures')"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">{{LANGAGE.listerfactures}}</p>
                  </a>
                </li>

              </ul>
            </li>

            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">
              <a _ngcontent-dpb-c106="" class="nav-link">
                <i class="fas fa-object-ungroup nav-icon"  style="color: #74b842;"></i>
                <p _ngcontent-dpb-c106=""   style="color: #74b842;">
                  {{LANGAGE.commande}}
                </p>
              </a>
              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">

                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a _ngcontent-dpb-c106="" (click)="toPage('listercommandes')"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">{{LANGAGE.listercommande}}</p>
                  </a>
                </li>
              </ul>
            </li>

            <li _ngcontent-dpb-c106="" class="nav-item has-treeview menu-open">
              <a _ngcontent-dpb-c106="" class="nav-link">
                <i class="fas fa-edit nav-icon"  style="color: #74b842;"></i>
                <p _ngcontent-dpb-c106=""   style="color: #74b842;">
                  {{LANGAGE.parametre}}
                </p>
              </a>
              <ul *ngIf="this.adminType == 'SUPERADMIN'" _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">
                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a _ngcontent-dpb-c106="" (click)="toPage('entreprise')"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">{{LANGAGE.entreprise}}</p>
                  </a>
                </li>
              </ul>
<!--              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">-->
<!--                <li _ngcontent-dpb-c106="" class="nav-item">-->
<!--                  <a _ngcontent-dpb-c106="" (click)="toPage('hashkey')"-->
<!--                     class="nav-link">-->
<!--                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>-->
<!--                    <p _ngcontent-dpb-c106="">Amazon aws</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->

              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">
                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a *ngIf="this.adminType != 'SUPERADMIN'" _ngcontent-dpb-c106="" (click)="toPage('contact')"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">{{LANGAGE.contact}}</p>
                  </a>
                  <a *ngIf="this.adminType == 'SUPERADMIN'" _ngcontent-dpb-c106="" (click)="toPage('message')"
                     class="nav-link">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                      <p _ngcontent-dpb-c106="">{{LANGAGE.message}}</p>
                      <div style="margin-left: 5px; padding-top: 5px; padding-bottom:5px ; padding-left: 10px; padding-right: 10px; border-radius: 50px; background-color: #33b827; display: flex; align-items: center; justify-content: center;">
                        {{countMessages}}
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">
                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a _ngcontent-dpb-c106="" (click)="toDocTechniques()"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">{{LANGAGE.doctechniques}}</p>
                  </a>
                </li>
              </ul>
              <ul _ngcontent-dpb-c106="" class="nav nav-treeview" style="display: block;">

                <li _ngcontent-dpb-c106="" class="nav-item">
                  <a _ngcontent-dpb-c106="" (click)="about()"
                     class="nav-link">
                    <i class="ml-2 mr-1 fa fa-arrow-right" aria-hidden="true"></i>
                    <p _ngcontent-dpb-c106="">{{LANGAGE.about}}</p>
                  </a>
                </li>
              </ul>
            </li>



          </ul>
        </nav>
      </div>
    </aside>

    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">{{title}}</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
            </div><!-- /.col -->
          </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content" [ngSwitch]="page">
        <div class="container-fluid" *ngSwitchCase="'home'">
          <div class="row">
            <div class="col-lg-3 col-6 pointer-pt">
              <div class="small-box bg-info">
                <div class="inner" style="flex-direction: row; align-items: center;">
                  <h3>{{countArticles}}</h3>
                  <p>{{LANGAGE.facture}}</p>
                  <div class="icon" style="margin-left: 60px;">
                    <i  class="fas fa-file-invoice fa-2x"></i>
                  </div>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a (click)="toPage('listerfactures')" class="small-box-footer">{{LANGAGE.detail}} <i
                  class="fas fa-arrow-circle-right"></i></a>
              </div>
            </div>

            <div class="col-lg-3 col-6 pointer-pt">
              <div class="small-box bg-success">
                <div class="inner" style="flex-direction: row; align-items: center;">
                  <h3>{{countCommandes}}</h3>
                  <p>{{LANGAGE.toutescommande}}</p>
                  <div class="icon" style="margin-left: 60px;">
                    <i  class="fas fa-shopping-bag fa-2x"></i>
                  </div>
                </div>
                <div class="icon">
                  <i class="ion ion-bag"></i>
                </div>
                <a (click)="toPage('listercommandes')" class="small-box-footer">Détail <i
                  style="margin-left: 5px;"  class="fas fa-arrow-circle-right"></i></a>
              </div>
            </div>

            <div class="col-lg-3 col-6 pointer-pt">
              <div class="small-box bg-danger">
                <div class="inner" style="flex-direction: row; align-items: center;">
                  <h3>{{countCommandesouvert}}</h3>
                  <p>{{LANGAGE.commandeouvertes}}</p>
                  <div class="icon" style="margin-left: 60px;">
                    <i  class="fas fa-shopping-bag fa-2x"></i>
                  </div>
                </div>

                  <a (click)="toPage('CommandeOuvertes')" class="small-box-footer">
                  {{LANGAGE.detail}}<i  style="margin-left: 5px;"  class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>


            <div class="col-lg-3 col-6 pointer-pt">
              <div class="small-box bg-indigo">
                <div class="inner" style="flex-direction: row; align-items: center;">
                  <h3>{{countLivraison}}</h3>
                  <p>{{LANGAGE.encourslivraison}}</p>
                  <div class="icon" style="margin-left: 60px;">
                    <i  class="fas fa-shopping-bag fa-2x"></i>
                  </div>
                </div>

                <a (click)="toPage('CommandeEncoursLivraison')" class="small-box-footer">
                  {{LANGAGE.detail}}<i style="margin-left: 5px;" class="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>



          </div>
          <div style="font-size: 17px; font-weight: bold; color:#8f8f8f; margin-bottom: 20px">
            {{LANGAGE.articleslepluscommande}}
          </div>

<!--          4 item les plus commandés-->
          <ul class="list-group" style="width:65%">
            <li *ngFor="let item of articlespluscommande4" class="list-group-item justify-content-between">
              {{item.item}}
<!--              <span class="badge badge-default badge-pill">14</span>-->
            </li>
          </ul>
        </div>

<!--        debut chart-->
<!--        <div class="row">-->
<!--          <div class="col-10">-->
<!--            <app-user-chart [dataChart]="[820, 932, 901, 934, 1290, 1330, 1320]" [type]="0" [titreChart]="'CHARTE'"></app-user-chart>-->
<!--          </div>-->
<!--        </div>-->
<!--          fin chart-->

        <div class="card" *ngSwitchCase="'facturepargroupe'">
          <app-FacturesParGroupe ></app-FacturesParGroupe>
        </div>
        <div class="card" *ngSwitchCase="'groupe'">
          <app-groupe ></app-groupe>
        </div>
        <div class="card" *ngSwitchCase="'admin'">
          <app-modificationadmin (navigateToNavigation)="funcnavigateToNavigation($event)"></app-modificationadmin>
        </div>
        <div class="card" *ngSwitchCase="'CommandeEncoursLivraison'">
          <app-CommandeEncoursLivraison></app-CommandeEncoursLivraison>
        </div>
        <div class="card" *ngSwitchCase="'CommandeOuvertes'">
          <app-CommandeOuvert></app-CommandeOuvert>
        </div>

        <div class="card" *ngSwitchCase="'entreprise'">
          <app-entreprise  (navigateToNavigation)="funcnavigateToNavigation($event)"></app-entreprise>
        </div>
        <div class="card" *ngSwitchCase="'message'">
          <app-message></app-message>
        </div>
        <div class="card" *ngSwitchCase="'listerfactures'">
          <app-listerfactures (navigateToNavigation)="funcnavigateToNavigation($event)"></app-listerfactures>
        </div>

        <div class="card" *ngSwitchCase="'listercommandes'">
          <app-listercommandes (navigateToNavigation)="funcnavigateToNavigation($event)"></app-listercommandes>
        </div>
        <div class="card" *ngSwitchCase="'hashkey'">
          <app-hashkey ></app-hashkey>
        </div>
        <div class="card" *ngSwitchCase="'contact'">
          <app-contact ></app-contact>
        </div>
      </section>
    </div>
    <footer class="main-footer">
    </footer>

    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>
</div>

<div class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
         <h4 class="text-center" style="font-weight: bold">{{LANGAGE.voulezvousvraimentdeconnecter}}</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="sign_out()">{{LANGAGE.oui}}</button>
        <button type="button" class="btn btn-outline-dark" data-dismiss="modal">{{LANGAGE.non}}</button>
      </div>
    </div>
  </div>
</div>
