

<div class="card-body">
  <div class="row">
    <!-- <table class="table"> -->
    <div class="form-group">
      <label>{{LANGAGE.email}}</label>
      <input disabled type="text" class="form-control" name="adminName" [(ngModel)]="email" style="width: 50%">
    </div>

    <div class="form-group">
      <label>{{LANGAGE.message1}}</label>
      <textarea type="text" class="form-control" name="hash" [(ngModel)]="message" style="width: 50%; height: 200px;"></textarea>
    </div>
    <div >
      <button  type="button" class="btn btn-info" style="background-color: #1b3762; color:#fff;" (click)="enregistrer()"><i class="fa fa-check-circle" aria-hidden="true"></i> {{LANGAGE.envoyer}}</button>
    </div>
  </div>
</div>






