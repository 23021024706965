import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common'
import { NgxCollapseModule } from 'ngx-collapse';
import {MatFormFieldModule} from '@angular/material/form-field';
import { RouteModule } from "./route/route.module";
import { NgxHorizontalTimelineModule } from 'ngx-horizontal-timeline';
import {NgxEchartsModule} from "ngx-echarts";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UserChartComponent } from './application/charts/user-chart/user-chart.component';

// SERVICE
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { TokenInterceptorService } from './services/token-interceptor.service';

// Module installe
import { DataTablesModule } from 'angular-datatables';
import { NgbModule, NgbPaginationModule, NgbAlertModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { BackButtonDisableModule } from 'angular-disable-browser-back-button'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastrModule } from 'ngx-toastr';

// component
import {
  LoginComponent,
  HomeComponent,
  MenuComponent,
  Validation_emailComponent,
  listercommandesComponent,
  commandeEncoursLivraisonComponent,
  commandeOuvertComponent,
  ModificationadminComponent,
  listerfacturesComponent,
  HashkeyComponent,
  ContactComponent,
  Entreprise,
  Message,
  GroupeComponent,
  facturesParGroupeComponent
} from "./application";

import { DateFilterComponent } from './shared/date-filter/date-filter.component';
import {MatSelectModule} from "@angular/material/select";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MenuComponent,
    DateFilterComponent,
    ModificationadminComponent,
    Validation_emailComponent,
    listercommandesComponent,
    commandeEncoursLivraisonComponent,
    commandeOuvertComponent,
    listerfacturesComponent,
    HashkeyComponent,
    ContactComponent,
    UserChartComponent,
    Entreprise,
    Message,
    GroupeComponent,
    facturesParGroupeComponent
    //
  ],
  imports: [
    ToastrModule.forRoot(),
    BrowserModule,
    RouteModule,
    HttpClientModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxCollapseModule,
    BackButtonDisableModule.forRoot({preserveScrollPosition: true}),
    NgbPaginationModule,
    NgbAlertModule,
    NgbRatingModule,
    NgxHorizontalTimelineModule,
    NgxEchartsModule.forRoot({echarts: () => import('echarts')}),

    // SocketIoModule.forRoot(config)
  ],
  providers: [
    DatePipe,
    {
      provide: LocationStrategy,
      //useClass: PathLocationStrategy
      useClass: HashLocationStrategy
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

