import { Component } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { AutoLogoutService } from 'src/app/services/autoLogout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(
    private locationStrategy: LocationStrategy,
    public autoLogoutService: AutoLogoutService
  ) {
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href)

    });
  }
  title = 'BO-Interf-WC';
}
