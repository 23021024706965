import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ENV_PROD} from "../../../../environments/config-env";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {Francais} from "../../../../environments/Francais";
import {Anglais} from "../../../../environments/Anglais";


@Component({
  selector: 'app-validation_email',
  templateUrl: './validation_email.component.html',
  styleUrls: ['./validation_email.component.css']
})
export class Validation_emailComponent implements OnInit {

  message: any
  constructor(private route:ActivatedRoute,
              private httpClient:HttpClient ) {}

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('param');
    this.httpClient.get<any>(ENV_PROD.urlApi + 'confirmation/'+param).subscribe(
      (response1) => {
          console.log("response",response1)
        if (response1.status == 200){
          this.message = response1.message;
        }
      })
  }

}
