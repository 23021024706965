<div class="card-body">
  <div style="display: flex; flex-direction: row; align-items: center;">
    <app-date-filter (itemConcat)="filterByDate($event)"></app-date-filter>
    <button class="btn btn-primary btn-sm pull-right" style="margin-left: 15px; height: 34px; margin-top: 10px; background-color: #274f8d; border-width: 0px;" (click)="generateExcel()">
      <i class="fa fa-file-invoice" aria-hidden="true"></i> {{LANGAGE.exporterexcel}}
    </button>
  </div>

  <div class="row" style="margin-top: 15px;">
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
<!--     <th><div>Active</div>/desactive</th>-->
        <th>{{LANGAGE.groupeid}}</th>
        <th>{{LANGAGE.commande1}}</th>
        <th>{{LANGAGE.numerofacture}}</th>
        <th>{{LANGAGE.compteclient}}</th>
        <th>{{LANGAGE.datecommande}}</th>
        <th>{{LANGAGE.referenceclient}}</th>
        <th>{{LANGAGE.montantHt}}</th>
        <th>{{LANGAGE.montantTVA}}</th>
        <th>{{LANGAGE.montantTTC}}</th>
        <th></th>
<!--        <th></th>-->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listerfactures" class="pointer-cl">
<!--        <td>-->
<!--          <input style="margin-left: 35%" type="checkbox" (change)="activedesactive(item?.id, $event)" [checked]="item.status ==='1'" >-->
<!--        </td>-->

        <td (click)="emitDetail(item)"  [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.groupe && item.groupe.groupeId && item.groupe.groupeId != null && item.groupe.groupeId !== '' && item.groupe.groupeId !== undefined) ? (item.groupe.groupeId | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)" ><div [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.numerocommande !== null && item.numerocommande !== '' && item.numerocommande !== undefined) ? (item.numerocommande | titlecase) : ''}}</div></td>
        <td (click)="emitDetail(item)"  [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.numerofacture !== null && item.numerofacture !== '' && item.numerofacture !== undefined) ? (item.numerofacture | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)"  [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.compteclient !== null && item.compteclient !== '' && item.compteclient !== undefined) ? (item.compteclient | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)"  [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.datedecommande !== null && item.datedecommande !== '' && item.datedecommande !== undefined) ? (item.datedecommande | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)"  [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.referenceclient !== null && item.referenceclient !== '' && item.referenceclient !== undefined) ? (item.referenceclient ) : ''}}</td>
        <td (click)="emitDetail(item)"  [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.montantht !== null && item.montantht !== '' && item.montantht !== undefined) ? (item.montantht | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)"  [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.montanttva !== null && item.montanttva !== '' && item.montanttva !== undefined) ? (item.montanttva | titlecase) : ''}}</td>
        <td (click)="emitDetail(item)"  [title]="(item.invoicename != null && item.invoicename !== '' && item.invoicename !== undefined) ? item.invoicename : ' ' + ' ' +  (item.invoiceadress != null && item.invoiceadress !== '' && item.invoiceadress !== undefined) ? item.invoiceadress : ' '">{{(item.montantttc !== null && item.montantttc !== '' && item.montantttc !== undefined) ? (item.montantttc | titlecase) : ''}}</td>

        <td style="display: flex; flex-direction: row; align-items: center;">
          <button class="btn btn-sm btn-outline-info"  style="margin-left: 10px; color: #274f8d; border-color: #1b3762;" (click)="versfacturepargroupe(item)">
            <i class="fas fa-people-arrows" ></i>
          </button>
        </td>
        <!--        <td style="display: flex; flex-direction: row">-->
<!--          <button style="margin-right: 5px;" class="btn btn-sm btn-secondary"  (click)="emitNavigateToShowArticles(item)">-->
<!--            <i class="fa fa-newspaper"></i>-->
<!--            <span *ngIf="item.articles.length>0" style="margin-left:4px; border-radius: 15px;-->
<!--                     padding-top:2px;padding-bottom: 2px;padding-left: 4px; padding-right: 4px;-->
<!--                      background-color: #478edb; color:#fff; font-size: 10px;">{{item.articles.length}}</span>-->
<!--          </button>-->
<!--          <button style="margin-right: 5px;" class="btn btn-sm btn-secondary"  (click)="emitNavigateToShowReservation(item)">-->
<!--            <i class="fa fa-cart-arrow-down"></i>-->
<!--            <span *ngIf="item.paiements.length>0" style="margin-left:4px; border-radius: 15px;-->
<!--                     padding-top:2px;padding-bottom: 2px;padding-left: 4px; padding-right: 4px;-->
<!--                      background-color: #478edb; color:#fff; font-size: 10px;">{{item.paiements.length}}</span>-->
<!--          </button>-->
<!--        </td>-->
<!--        <td><button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">-->
<!--          <i class="fas fa-trash"></i>-->
<!--        </button>-->
<!--        </td>-->
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #confirmDelete>
  <div class="card-body">
    {{LANGAGE.etesvoussurdevouloirsupprimer}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="delete()">{{LANGAGE.oui}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">{{LANGAGE.non}}</button>
  </div>
</ng-template>

<ng-template #actiondetails>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold">{{LANGAGE.detail}}</h4>
    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label >{{LANGAGE.numerocommande}} {{Item.numerocommande}}</label>
      <div style="padding-top: 10px"><label >{{LANGAGE.numerofacture1}} {{Item.numerofacture}}</label></div>
      <div style="padding-top: 10px"><label >{{LANGAGE.client}} {{details.Message.Header.Address.Supplier.SupplierName._text }}</label></div>
      <div style="padding-top: 10px"><label >{{LANGAGE.adresse}} {{details.Message.Header.Address.Delivery.DeliveryAddress._text }}</label></div>
      <div style="padding-top: 10px"><label >{{LANGAGE.livreur}} {{details.Message.Header.Address.Delivery.DeliveryName._text }}</label></div>

      <div *ngIf="!typeoff(details.Message.Lines.Line)"   style="font-size: 16px;">
        <div style="padding-top: 10px; padding-bottom: 10px"><label >{{LANGAGE.article}}</label></div>
        {{details.Message.Lines.Line.ItemDescription ? details.Message.Lines.Line.ItemDescription.Primary ? details.Message.Lines.Line.ItemDescription.Primary._text :'' : ''}}
      </div>
      <div *ngIf="typeoff(details.Message.Lines.Line)" style="font-size: 16px;">
        <div style="padding-top: 10px; margin-bottom: 10px; font-style: italic"><label >{{LANGAGE.articles}}</label></div>
        <ul class="list-group" style="width:99%">
          <li *ngFor="let item of details.Message.Lines.Line" class="list-group-item justify-content-between">
            {{item.ItemDescription.Primary._text}}
          </li>
        </ul>
      </div>
    </div>

  </div>
</ng-template>


