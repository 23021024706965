
<div class="card-head" style="display: flex; flex-direction: row; align-items: center;">
<!--  *ngIf="adminType=='SUPERADMIN'"-->

  <button  class="btn btn-success btn-sm pull-right" (click)="openModal(actionadmin)">
    <i class="fa fa-plus" aria-hidden="true"></i> {{LANGAGE.nouveau}}
  </button>
  <button  *ngIf="listentreprise.length ==0" class="btn btn-info btn-sm pull-right"  style="margin-left: 20px; background-color: #1b3762; color:#fff;" (click)="versgroupe(entrepriseId)">
    <i class="fas fa-people-arrows" aria-hidden="true"></i> {{LANGAGE.groupe}}
  </button>
</div>

<div class="card-body">
  <div class="row">
    <!-- <table class="table"> -->
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTigger">
      <thead>
      <tr>
        <th  *ngIf="listentreprise.length>0">{{LANGAGE.type}}</th>
        <th  *ngIf="listentreprise.length>0">{{LANGAGE.nomEntreprise}}</th>
        <th>{{LANGAGE.nom}}</th>
        <th>{{LANGAGE.email}}</th>
        <!--                            <th>Mot de passe</th>-->
        <!--                            <th>Statut</th>-->
        <th>{{LANGAGE.adresse}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of listadmin" class="pointer-cl">
        <td  *ngIf="listentreprise.length>0" (click)="emitDataadmin(item)">{{item.type}}</td>
        <td *ngIf="listentreprise.length>0 && item.nomEntreprise" (click)="emitDataadmin(item)">{{item.nomEntreprise}}</td>
        <td *ngIf="listentreprise.length>0 && !item.nomEntreprise" (click)="emitDataadmin(item)"></td>
        <td (click)="emitDataadmin(item)">{{item.nom}}</td>
        <td (click)="emitDataadmin(item)">{{item.email}}</td>
        <!--                            <td (click)="emitDataadmin(item)">{{decryptUsingAES256(item.password)}}</td>-->
        <!--                            <td (click)="emitDataadmin(item)">{{item.type == '1' ? 'Admin': 'admin'}}</td>-->
        <td (click)="emitDataadmin(item)">{{item.adresse}}</td>
        <td style="display: flex; flex-direction: row; align-items: center;">
          <button class="btn btn-sm btn-danger" (click)="beforeDelete(item.id)">
            <i class="fas fa-trash"></i>
          </button>
          <button *ngIf="listentreprise.length>0"  style="margin-left: 10px; color: #274f8d; border-color: #1b3762;" class="btn btn-sm btn-outline-info" (click)="versgroupe(item)">
            <i class="fas fa-people-arrows"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #actionadmin>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold" >{{LANGAGE.nouveauadmin}}</h4>
    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="adminType=='SUPERADMIN'" class="form-group">
      <label>Type</label>
      <select  [(ngModel)]="entrepriseId" [ngModelOptions]="{standalone: true}" class="form-control">
              <option value="ADMIN">{{LANGAGE.admin}}</option>
        <option *ngFor="let item of listentreprise; let y = index;"  value="{{item.id}}">{{item.nom}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{LANGAGE.nom}}</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="adminName">
    </div>

    <div class="form-group">
      <label>{{LANGAGE.email}}</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="adminEmail">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.adresse}}</label>
      <input type="text" class="form-control" name="adminAdress" [(ngModel)]="adminAdress">
    </div>

    <div class="form-group">
      <label>{{LANGAGE.motdepasse}}</label>
      <input type="password" class="form-control" name="adminPassword" [(ngModel)]="adminPassword">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.confirmPassword}}</label>
      <input type="password" class="form-control" name="adminconfirmPassword" [(ngModel)]="adminconfirmPassword">
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" style="background-color: #1b3762; color:#fff;"  (click)="addadmin()"><i class="fa fa-plus-circle" aria-hidden="true"></i> {{LANGAGE.ajouter}}</button>
  </div>
</ng-template>


<ng-template #actionadmin1>
  <div class="modal-header">
    <h4 class="modal-title" style="font-size:20px; font-weight: bold">{{LANGAGE.modificationadmin}}</h4>

    <div type="button" class="close" aria-label="Close" (click)="resetField()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="adminType=='SUPERADMIN'" class="form-group">
      <label>Type</label>
      <select [(ngModel)]="entrepriseId" [ngModelOptions]="{standalone: true}" class="form-control">
        <option  value="ADMIN">{{LANGAGE.admin}}</option>
        <option  *ngFor="let item of listentreprise; let y = index;"  [selected]="item.id == entrepriseId? true :false" value="{{item.id}}">{{item.nom}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{LANGAGE.nom}}</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="adminName">
    </div>

    <div class="form-group">
      <label>{{LANGAGE.email}}</label>
      <input type="text" class="form-control" name="adminName" [(ngModel)]="adminEmail">
    </div>
    <div class="form-group">
      <label>{{LANGAGE.adresse}}</label>
      <input type="text" class="form-control" name="adminAdress" [(ngModel)]="adminAdress">
    </div>
    <div class="form-group">
      <div style="margin-top: 20px; margin-bottom: 10px;" >
        <input name="ismodifierpassword" id="ismodifierpassword" type="checkbox"  [(ngModel)]="isUpdateWithPassword"  (change)="ismodifierpassword($event)" >
        <label  style="margin-left: 10px; color: #1da1f2; font-weight: bold;" class="form-check-label" for="ismodifierpassword">
          {{LANGAGE.modifieraussilepass}}
        </label>
      </div>
      <label *ngIf="isUpdateWithPassword === false">Mot de passe</label>
      <label *ngIf="isUpdateWithPassword === true">Ancien mot de passe</label>
      <input type="password" class="form-control" name="adminPassword" [(ngModel)]="adminPassword">
    </div>

    <div class="form-group">
      <label *ngIf="isUpdateWithPassword === true">Nouveau mot de passe</label>
      <input *ngIf="isUpdateWithPassword === true" type="password" class="form-control" name="ancienPassword" [(ngModel)]="nouveauPassword">

    </div>
    <div class="form-group">
      <label *ngIf="isUpdateWithPassword === true">confirmer le nouveau mot de passe</label>
      <input *ngIf="isUpdateWithPassword === true" type="password" class="form-control" name="adminconfirmPassword" [(ngModel)]="adminconfirmPassword">
    </div>

  </div>
  <div class="modal-footer">
    <button  type="button" class="btn btn-info" style="background-color: #1b3762; color:#fff;" (click)="updateadmin()"><i class="fa fa-check-circle" aria-hidden="true"></i> Enregister</button>
  </div>
</ng-template>

<ng-template #confirmDelete>
  <div class="card-body">
    Etes-vous sûr de vouloir supprimer?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="deleteadmin()">OUI</button>
    <button type="button" class="btn btn-outline-dark" (click)="resetField()">NON</button>
  </div>
</ng-template>

