import { Component, OnInit, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { DatatableLanguage } from "../../../constant/french-datatable";
import { Subject } from "rxjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import * as CryptoJS from 'crypto-js';
import {NgxSpinnerService} from "ngx-spinner";
import {ENV_PROD} from "../../../../environments/config-env";
import {catchError, retry} from "rxjs/operators";
import {AlertService} from "../../../services/alert.service";
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";
import {Francais} from "../../../../environments/Francais";
import {Anglais} from "../../../../environments/Anglais";


@Component({
  selector: 'app-message',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class Message implements OnInit, OnChanges {
  @ViewChild('actionmessage') actionmessage: any;
  @ViewChild('actionmessage1') actionmessage1: any;
  @ViewChild('actionmessage2') actionmessage2: any;
  @ViewChild('confirmDelete') confirmDelete: any;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  public listmessage: any = [];
  public dtOptions: any = {};
  public dtTigger = new Subject();
  // variable action emit
  public emitData: EventEmitter<any> = new EventEmitter;
  // variable base
  public messageId: any;
  public Item: any;
  public newmessage: any;
  public Subject: any;
  // check action
  public LANGAGE: any = Francais;

  constructor(
    private httpClient: HttpClient,
    private ngbModal: NgbModal,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.dtOptions = {
      language: DatatableLanguage.datatableFrench,
      lengthMenu:[50,70,100]
    };
    this.spinner.show();
    this.getallmessage();
  }

  ngOnChanges(): void {
    const langage = localStorage.getItem('LANGAGE');
    if(langage == 'Fr'){
      this.LANGAGE = Francais;
    } else{
      this.LANGAGE = Anglais;
    }
    this.loadmessage()
  }

  /**
   * loadmessage
   */
  public loadmessage(): void {
    this.spinner.show();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.getallmessage()
    })
  }

  private getallmessage(){
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      ////'Content-Type': 'application/json',
      Accept: "application/json",
       Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'message/getAll',
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          console.log('reslt', res);
          this.spinner.hide();
          if (res.status === 200) {
            this.listmessage = res.data;
            this.dtTigger.next()
          }
        });
  }


   /**
   * beforeDelete
   */
  public beforeDelete(itemId: any) {
    this.messageId = itemId;
    this.emitData.emit(itemId);
    this.ngbModal.open(this.confirmDelete)
  }

   /**
   * addmessage
   */
  // public addmessage() {
  //
  //    if(this.messageName === ""){
  //      alert("Le Nom ne pourrait-être pas vide.");
  //      return;
  //    }
  //
  //   let body = {
  //     nom: this.messageName,
  //     adresse: this.messageAdress,
  //   };
  //    const token = localStorage.getItem('userToken');
  //    const headers = new HttpHeaders({
  //      'Content-Type': 'application/json',
  //      Accept: "application/json",
  //      Authorization: "Bearer " + token
  //    });
  //    this.spinner.show();
  //    setTimeout(()=>this.spinner.hide(),10000);
  //    this.httpClient.post<any>(ENV_PROD.urlApi + 'message/add',
  //      JSON.stringify(body),
  //      { headers: headers, }
  //  ).pipe(retry(1), catchError(this.alertService.handleError))
  //      .subscribe(
  //        (res) => {
  //          this.spinner.hide();
  //          if(res.status && res.status === 404 && res.message ){
  //            alert(ENV_PROD.nom_application + " \n " + res.message);
  //          }
  //          this.loadmessage();
  //          this.resetField();
  //        });
  // }



  /**
   * deletemessage
   */
  public deletemessage() {
    const token = localStorage.getItem('userToken');
    const headers = new HttpHeaders({
      Accept: "application/json",
      Authorization: "Bearer " + token
    });
    this.httpClient.get<any>(ENV_PROD.urlApi + 'message/delete/'+this.messageId,
      { headers: headers }
    ).pipe(retry(1), catchError(this.alertService.handleError))
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.loadmessage();
          this.resetField();
          if(res.status != 200){
            this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
          }
        });

  }

  /**
   * openModal
   */
  public openModal(modal) {
    this.ngbModal.open(modal)
  }


  /**
   * emitDatamessage
   */
  public async emitDatamessage(item: any) {
    this.emitData.emit(item);
    this.messageId = item.id;
    this.Item = item;
    await <any>this.ngbModal.open(this.actionmessage1)
  }

   // GEstion field data
   public resetField() {
    this.ngbModal.dismissAll();
  }

  public async repondre(item : any) {
    this.Item = item;
    await <any>this.ngbModal.open(this.actionmessage2)
  }

  public async reponse() {


       if(this.newmessage === ""){
         alert(this.LANGAGE.lemessagenepourraitvide);
         return;
       }

      let body = {
        message: this.newmessage,
        item: this.Item,
        subject: this.Subject
      };
       const token = localStorage.getItem('userToken');
       const headers = new HttpHeaders({
         'Content-Type': 'application/json',
         Accept: "application/json",
         Authorization: "Bearer " + token
       });
       this.spinner.show();
       setTimeout(()=>this.spinner.hide(),10000);
       this.httpClient.post<any>(ENV_PROD.urlApi + 'message/send',
         JSON.stringify(body),
         { headers: headers, }
     ).pipe(retry(1), catchError(this.alertService.handleError))
         .subscribe(
           (res) => {
             this.spinner.hide();
             this.toastr.success(res.message, ENV_PROD.nom_application,{timeOut: 6000,positionClass: 'toast-bottom-right',});
             this.loadmessage();
             this.resetField();
           });
  }



}
